.toggle-menu {
  display: none; /* Hide checkbox by default */
}

.menu-items {
  padding: 0;
  margin: 0;
  background: transparent;
  height: 100vh;
  width: 100%;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
  background-image: url("./assets/images/menu.png");
  background-size: cover;
  height: 100vh;
  background-image: url("./assets/images/menu.png");
  background-size: cover;
  height: 100vh;
  z-index: 9999;
}

.menu-items.open {
  transform: translateX(0);
}

#nav ul {
  list-style: none;
  font-family: verdana, sans-serif;
  font-weight: bold;
  text-align: left;
  width: 100%;
  padding: 0;
}

#nav .listiFirst {
  background-color: transparent;
  border-bottom: 1px solid #ffffff12;
  display: block;
  text-decoration: none;
  padding-bottom: 4px;
  padding-top: 20px;
}

.navlinkfirst {
  font-size: 18px;
  color: var(--navbar-link-color);
  font-family: "Roboto-Medium";
  margin-left: 10px;
  font-weight: 500;
}

#nav a:hover {
  background-color: transparent;
  color: #0176db;
}

#nav a:active {
  background-color: transparent;
  color: #0176db;
}

#nav .dropdown .orderlisti {
  background-color: transparent;
  border-bottom: 1px solid #ffffff12;
  display: block;
  text-decoration: none;
  padding-bottom: 4px;
  padding-top: 20px;

  font-size: 18px;
  color: var(--navbar-link-color);
  font-family: "Roboto-Medium";
  font-weight: 500;
  margin-left: 10px;
}

#nav .dropdown .listi {
  background-color: transparent;
  margin-left: 30px;
  font-size: 13px;
  color: var(--navbar-link-color);
  font-family: "UrbanistReg";
}

#nav .dropdown:hover {
  background-color: transparent;
  color: #0176db;
}
#nav .dropdown:active {
  background-color: transparent;
  color: #0176db;
}
#nav .dropdown .listi:hover {
  background-color: transparent;
  color: #0176db;
}
#nav .dropdown .listi:active {
  background-color: transparent;
  color: #0176db;
}

/* Navbar Section */
.navbar-nav.first .nav-link {
  font-size: 12px;
  /* line-height: 24.59px; */
  color: var(--navbar-link-color);
  font-family: "ManropeRegular";
  margin-left: 10px;
  text-transform: uppercase;
}
.navbar-nav.first .nav-item.dropdown .dropdown-menu .dropdown-item {
  font-size: 18px;
  /* line-height: 24.59px; */
  color: var(--navbar-link-color);
  font-family: "ManropeRegular";
}
/* Active link color */
.navbar-nav.first .nav-link.active {
  color: var(--navbar-active-color);
  font-family: "ManropeRegular";
}
.navbar-nav.first .nav-link:hover {
  color: var(--navbar-active-color);
  font-family: "ManropeRegular";
}
.dropdown-item.listNumberApp{
  font-size: 12px !important;
}

/* Active dropdown link color */
.navbar-nav.first .nav-item.dropdown .dropdown-menu .dropdown-item.active,
.navbar-nav.first .nav-item.dropdown .dropdown-menu .dropdown-item:hover {
  color: var(--navbar-active-color);
  font-family: ManropeExtraBold;
}
.full_width_nav {
  width: 14%;
}
.back_color_trans {
  background-color: transparent !important;
  border: 1px solid transparent;
}
.back_color_trans1 {
  border: 1px solid #424242b0 !important;
  backdrop-filter: blur(8px);
  border-radius: 10px;
  background: transparent;
  backdrop-filter: blur(8px);
  margin-top: 10px;
}
.navbar-full .dropdown-menu {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
}
.navbar-toggler {
  border: none;
}
.navbar-full .dropdown,
.navbar-full .navbar-nav .nav-item.dropdown {
  position: static !important;
}
.modal-body .service_modal {
  margin-top: 10px;
  border-radius: 13px;
  border: 1px solid #343434 !important;
  background: rgba(0, 3, 9, 0.52) !important;
  /* width: 75% !important; */
  backdrop-filter: blur(15px);
}
.modal_navbar_p {
  color: var(--text-light-gray);
  /* line-height: 17.59px !important; */
  font-size: 11px;
  font-family: "ManropeRegular";
}
.modal-body {
  padding: 0 !important;
}
.modal_navbar_box {
  background: #03080e !important;
  width: 100%;
  height: 120px;
  border: 1px solid #0a76db;
}
@media screen and (min-width: 994px) and (max-width: 1200px) {
  .modal_navbar_box {
    height: 140px !important;
  }
}
/* .modal_content_nav {
  background-color: red;
  margin-left: -25px !important;
} */
/* .modal-content.modal_content_nav {
  border: none !important;
} */
.modal_navbar_p1 {
  font-size: 13px;
}
.home_paragrpgh_1 {
  /* line-height: 24.59px; */
  font-size: 15px;
  font-family: "ManropeRegular";
  color: rgba(255, 255, 255, 1);
  width: 60%;
}
.home_h3 {
  font-size: 28px;
}
@media screen and (max-width: 768px) {
  .full_width_nav {
    width: 100%;
  }
}
/* ________________________________________ */
/* Home Section */
.home_paragrpgh {
  /* line-height: 24.59px; */
  font-size: 15px;
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}
.home_h3 {
  /* line-height: 40.73px; */
  font-family: ManropeMedium;
  color: var(--navbar-link-color);
}

.home_btn {
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 30px;
  background-color: var(--navbar-link-color);
  font-family: ManropeBold;
  /* line-height: 24.59px; */
  color: var(--home-btn-color);
  border: 1px solid transparent;
}
.home_btn:hover {
  background-color: transparent;
  color: var(--navbar-link-color);
  border: 1px solid var(--navbar-link-color);
}
.home_btn1 {
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 30px;
  background-color: var(--navbar-link-color);
  font-family: ManropeBold;
  color: var(--home-btn-color);
  border: 1px solid transparent;
}
.home_btn1:hover {
  background-color: transparent;
  color: var(--navbar-link-color);
  border: 1px solid var(--navbar-link-color);
}

@media screen and (max-width: 768px) {
  .home_btn1 {
    padding: 10px;
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--navbar-link-color);
    font-family: ManropeBold;
    font-size: 10px;
    color: var(--home-btn-color);
    border: 1px solid transparent;
  }
  .home_btn1:hover {
    /* padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    background-color: transparent;
    font-family: ManropeBold;
    font-size: 10px;
    color: var(--navbar-link-color);
    border: 1px solid var(--navbar-link-color); */
    padding: 10px;
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--navbar-link-color);
    font-family: ManropeBold;
    font-size: 10px;
    color: var(--home-btn-color);
    border: 1px solid transparent;
  }
}
@media screen and (max-width: 576px) {
  .home_h3 {
    font-size: 18px;
    /* line-height: 25.73px; */
    font-family: ManropeMedium;
    color: var(--navbar-link-color);
  }
  .home_paragrpgh_1 {
    font-size: 10px;
    width: 100%;
  }
  /* .home_btn {
  } */
  .home_btn {
    padding: 10px;
    font-size: 13px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 30px;
    background-color: var(--navbar-link-color);
    font-family: ManropeBold;
    color: var(--home-btn-color);
    border: 1px solid transparent;
  }
  .home_paragrpgh {
    font-size: 11px;
  }
  .brand {
    width: 33.33% !important;
  }
  .headingSty4.HomeHeading {
    font-size: 17px !important;
  }

  .harnessing {
    padding: 5px !important;
    margin: 15px 24px !important;
  }
  .background_element_img {
    background-image: none !important;
  }
}
/* ________________________________________ */
#video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8) !important;
}
/* Style the video element */
.webPage_box4 {
  position: relative;
}
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Ensure the video is behind other content */
}
.content {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
}
/* ______________________________ */
/* Footer Section */
.footer_para {
  font-size: 12px;
  width: 85%;
  /* line-height: 21.86px; */
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}
.footer_para_1:hover {
  color: var(--navbar-active-color) !important;
}
.footer_para_mail {
  font-size: 12px;
  width: 85%;
  /* line-height: 21.86px; */
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}
.footer_para_mail:hover {
  color: var(--navbar-active-color) !important;
}
.heading_decoration_none {
  font-size: 12px;
  /* line-height: 13.86px; */
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}
.footer_para_1 {
  font-size: 12px;
  /* line-height: 13.86px; */
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}
.footer_para_2:hover {
  color: var(--navbar-active-color);
}
.footer_para_2 {
  font-size: 12px;
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}
.circle-div {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid var(--navbar-link-color);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--navbar-link-color);
}
.circle-div:hover {
  background-color: var(--navbar-active-color);
}
.visiblty {
  visibility: hidden;
}
.footer_h5 {
  color: var(--navbar-link-color);
  font-size: 15px;
  /* line-height: 21.86px; */
  font-family: "ManropeRegular";
}
.footer_hr {
  border-top: 1px solid var(--text-light-gray);
  margin: 0;
  padding: 0;
}
.bottom_footer_flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.footer_main_div {
  background-color: var(--footer-background-color);
}
.footer_para_4 {
  font-size: 12px;
  /* line-height: 21.86px; */
  font-family: "ManropeRegular";
  color: var(--navbar-link-color);
}
@media (max-width: 1215px) {
  .footer_para_space {
    height: 55px;
  }
}
@media (max-width: 768px) {
  .footer_para_2 {
    font-size: 11px;
    font-family: "ManropeRegular";
    color: var(--navbar-link-color);
  }
  .footer_para_mail {
    font-size: 11px;
  }
  .footer_para {
    font-size: 11px;
    width: 85%;
    /* line-height: 21.86px; */
    font-family: "ManropeRegular";
    color: var(--navbar-link-color);
  }
  .footer_hr {
    margin-left: 12px;
    margin-right: 12px;
  }
}

/* ___________________________________________ */
/* Slider Section */
.arrow_circle_slider {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--navbar-link-color);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--navbar-link-color);
}
.slider_h2 {
  font-size: 27px !important;
  font-family: UrbanistReg;
  color: var(--home-btn-color);
}
.slider_p {
  font-family: "ManropeRegular";
  font-size: 15px;
  color: var(--home-btn-color);
}
/* Style the video element */
#slider-video {
  border-radius: 20px;
  width: 90%;
  height: 100%;
}
@media (max-width: 576px) {
  #slider-video {
    border-radius: 20px;
    width: 70%;
    height: 100%;
  }
  .slider_p {
    font-family: "ManropeRegular";
    font-size: 11px;
    color: var(--home-btn-color);
  }
  .footer_para_4 {
    font-size: 9px;
    /* line-height: 21.86px; */
    font-family: "ManropeRegular";
    color: var(--navbar-link-color);
  }
}
.slider_background {
  background-image: url("./assets/images/slider_background.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  /* height: 100vh; */
}
@media (max-width: 576px) {
  slider_background {
    background-size: contain;
  }
  #slider-video {
    border-radius: 20px;
    width: 100%;
  }
}
/* Slider Dots Style */
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: white;
  border: 2px solid #123777;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.carousel-indicators .active {
  background-color: #123777 !important;
  width: 35px !important;
  height: 7px !important;
  border-radius: 20px !important;
}
/* ___________________________________________ */
/* About Page */
.about_main_div {
  background-image: url("./assets/images/BGAbout.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.aboutpage_h3.careerText {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.aboutpage_h3 {
  color: var(--navbar-link-color);
  font-family: UrbanistReg;
}
.aboutpage_para {
  color: var(--navbar-link-color);
  font-family: "ManropeRegular";
  font-size: 14px;
}
@media (max-width: 576px) {
  .aboutpage_h3 {
    color: var(--navbar-link-color);
    font-family: UrbanistReg;
    font-size: 29px !important;
  }
  .aboutpage_para {
    color: var(--navbar-link-color);
    font-family: "ManropeRegular";
    font-size: 11px;
  }
}
/* ___________________________________________ */
/* Home Page Element Section */
.background_element_img {
  background-image: url("./assets/images/Homepage\ BG\ Elements.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
/* ___________________________________________ */
/* Contact Page */
.main_div_conti {
  background-image: url("./assets/images/BGDotsMap.png");
  background-position: center;
  width: 100%;
  /* height: 100vh; */
  background-size: cover;
  background-repeat: no-repeat;
}
.contact_map_div {
  background-image: url("./assets/images/BGMap.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.contact_main_div {
  background-image: url("./assets/images/BGContact.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.main_div_conti2 {
  background-color: #f7fcff;
}
.contactpage_h3_1 {
  font-size: 15px;
  font-family: "UrbanistReg";
}
.contact_form {
  font-family: ManropeRegular;
  position: relative;
  background-color: #fdfdfd;
  border-radius: 20px;
  padding: 10px 10px;
  box-shadow: 0px 14.800000190734863px 48.75777816772461px 0px #0000001a;
  z-index: 12;
}
.contactpage_p_1 {
  color: #2b2b2b;
  font-family: ManropeRegular;
  font-weight: 400;
  line-height: normal;
  font-size: 10px;
}
.contactpage_p_2 {
  color: #303030;
  font-family: ManropeRegular;
  font-weight: 400;
  line-height: normal;
  font-size: 13px;
}
.contact_main_div2 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}
.contacti_h3 {
  font-size: 27px;
  font-weight: 300;
  font-family: "UrbanistReg";
  /* line-height: 38px; */
  color: #2b2b2b;
}
.maping {
  width: 100%;
  height: 160px;
  border-radius: 8px;
  margin-top: 6%;
}
.location_box_cont {
  border-radius: 9.769px;
  border: 2.016px solid rgba(246, 246, 246, 0.6);
  background: #fdfdfd;
  box-shadow: 0px 9.072px 29.886px 0px rgba(0, 0, 0, 0.1);
  display: block;
}
.activeBtn.careerDetailActiveBtn {
  width: 35px;
  height: 35px;
}
.inactiveBtn.careerDetailActiveBtn {
  width: 35px;
  height: 35px;
}
.contact_h3_align {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 75%;
}
.main_div_conti3 {
  height: 750px;
}

.global_contacti {
  position: absolute;
  top: 70%;
}

@media only screen and (min-width: 1950px) and (max-width: 4000px) {
  .main_div_conti3 {
    height: 1200px;
  }

  .global_contacti {
    position: absolute;
    top: 81%;
  }
}
@media only screen and (min-width: 769px) and (max-width: 993px) {
  .main_div_conti3 {
    height: 850px;
  }
  .global_contacti {
    display: none;
  }
}
@media only screen and (min-width: 573px) and (max-width: 768px) {
  .main_div_conti3 {
    height: 850px;
  }
}
@media (max-width: 768px) {
  .contact_h3_align {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .main_div_conti {
    background-color: #f7fcff;
    width: 100%;
    background-image: none;
  }
}
@media (max-width: 576px) {
  .contact_main_div {
    background-image: url("./assets/images/BGDelete.png");
    background-position: center;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .contactpage_h3_1 {
    font-size: 14px;
  }
  .contactpage_p_1 {
    font-size: 9px;
  }
  .contact_main_div2 {
    box-shadow: none;
    background-color: transparent;
  }
  .maping {
    width: 90%;
    height: 130px;
    border-radius: 6px;
    margin-top: 0%;
  }
  .contact_h3_align {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .contactpage_p_2 {
    display: none;
  }
  .contacti_h3.contactPage {
    font-size: 19px !important;
  }
  .contacti_h3 {
    font-size: 13px;
    /* line-height: 1px; */
    margin-bottom: 13%;
    text-align: center;
  }
  .contact_form {
    font-family: ManropeRegular;
    position: relative;
    background-color: #fdfdfd;
    border-radius: 10px;
    box-shadow: 0px 14.800000190734863px 48.75777816772461px 0px #0000001a;
    z-index: 12;
  }
  .checkbox_lable {
    font-size: 14px !important;
    color: #4e4e4e !important;
    font-weight: 500 !important;
  }
  .contactPage_h3.otherPositions,
  .contactPage_h3.aboutJob {
    font-size: 29px !important;
    font-weight: 300;
    font-family: "UrbanistReg";
  }
  .careers_h3_2 {
    color: #2b2b2b;
    font-family: UrbanistSemiBold !important;
    font-size: 22px;
  }
  .inactiveBtn {
    width: 40px !important;
    height: 40px !important;
  }
  .activeBtn {
    width: 40px !important;
    height: 40px !important;
  }
  .main_div_conti3 {
    height: auto !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .contactpage_h3_1 {
    font-size: 13px !important;
    font-family: "UrbanistReg";
  }
}

.contactPage_p {
  font-size: ManropeRegular;
  font-size: 15px;
}
.contactPage_h3.otherPositions,
.contactPage_h3.aboutJob {
  font-size: 38px;
  font-weight: 300;
  font-family: "UrbanistReg";
}
.contactPage_h3 {
  font-size: 32px;
  font-weight: 300;
  color: #2b2b2b;
  font-family: "UrbanistReg";
}
.contacti {
  height: 500px;
  background-color: #e7f2ff;
}
.main_contacti_input input {
  border: none !important;
  outline: none !important;
  padding-left: 5px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  display: block !important;
  width: 100% !important;
  font-size: 1rem !important;
  margin: 0 !important;
  color: #4e4e4e !important;
  border-radius: var(--bs-border-radius) !important;
  background-color: transparent !important;
}
.main_contacti_input input::placeholder {
  color: #4e4e4e !important;
  font-size: 14px;
  font-family: ManropeRegular;
  font-weight: 400;
}
.main_number_input input::placeholder {
  color: #4e4e4e !important;
  font-size: 14px;
  font-family: ManropeRegular;
  font-weight: 400;
}
.select_conta {
  width: 35%;
}
.main_contacti_input input:focus {
  border: none !important;
  outline: none !important;
  padding-left: 5px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  display: block !important;
  width: 100% !important;
  font-size: 1rem !important;
  margin: 0 !important;
  color: var(--bs-body-color) !important;
  border-radius: var(--bs-border-radius) !important;
  background-color: transparent !important;
}

.main_number_input input {
  border: none !important;
  outline: none !important;
  padding: 0px !important;
  display: block !important;
  width: 100% !important;
  font-size: 1rem !important;
  margin: 0 !important;
  color: var(--bs-body-color) !important;
  border-radius: var(--bs-border-radius) !important;
  background-color: transparent !important;
}
.main_number_input input:focus {
  border: none !important;
  outline: none !important;
  padding: 0px !important;
  display: block !important;
  width: 100% !important;
  font-size: 1rem !important;
  margin: 0 !important;
  color: var(--bs-body-color) !important;
  border-radius: var(--bs-border-radius) !important;
  background-color: transparent !important;
}

.top_margin_input {
  margin-top: 10%;
}
.dropdownNumberAppConta {
  width: 100%;
  /* padding-top: 11px; */
  /* padding-bottom: 11px; */
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 12px;
}

.dropdownSelectiApp.show {
  border: 1px solid transparent !important;
  color: var(--bs-body-color);
}
.dropdownSelectiApp {
  width: 100%;
  /* padding: 9px 10px; */
  padding-left: 3px;
  background-color: transparent !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent !important;
  border-radius: var(--bs-border-radius);
}

.form-group .nomi {
  display: none; /* Hide the default checkbox */
  border-radius: none !important;
}

.form-group .nomi + label:before {
  content: "";
  background-color: transparent !important;
  border: 2px solid #848484;
  border-radius: 5% !important;
  padding: 8px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px !important;
}

.form-group .nomi:checked + label:before {
  background-color: #0a76db !important; /* Change background color when checked */
  border: 2px solid #0a76db !important;
}
.border_bottom_input{
  border-bottom: 1px solid #BABABA;
}

.checkbox_lable {
  color: #4e4e4e !important;
  font-size: 14px;
  font-family: ManropeRegular;
  font-weight: 400;
}

.form-group .nomi:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5.5px;
  left: 8px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.top_margin_btn {
  margin-top: 20%;
  margin-bottom: 6%;
}

.dots_contacti {
  position: absolute;
  top: 75%;
  left: 80%;
}
@media (max-width: 1024px) {
  .contacti {
    height: 850px;
    background-color: #e7f2ff;
  }
}
@media (max-width: 768px) {
  .contacti {
    height: 1250px;
    background-color: #e7f2ff;
  }
  .dots_contacti {
    display: none;
  }
  .global_contacti {
    display: none;
  }
}
@media (max-width: 576px) {
  .select_conta {
    width: 20%;
  }
  .dropdownNumberAppConta {
    width: 100%;
    /* padding-top: 11px;
    padding-bottom: 11px; */
    padding-left: 3px;
    justify-content: start;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 12px;
  }
  .contacti {
    height: 1520px;
    background-color: #e7f2ff;
  }
  .location_box_cont {
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
  }
  .top_margin_input {
    margin-top: 10%;
  }
  .top_margin_btn {
    margin-top: 15%;
    margin-bottom: 6%;
  }
}
.main_map_div {
  height: 125vh;
}
/* ___________________________________________ */
/* Legal Page */
.legal_main_div {
  background-image: url("./assets/images/BGPrivacy.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.legal_h3 {
  color: var(--text-light-black);
  font-family: UrbanistReg;
  font-size: 30px;
}
.legal_h5 {
  color: var(--text-light-black);
  font-family: "UrbanistReg";
  /* font-weight: 600; */
  font-size: 24px;
}
.legal_p {
  color: var(--text-light-black);
  font-family: ManropeMedium;
  font-weight: 400;
  font-size: 15px;
}
@media screen and (max-width: 768px) {
  .legal_main_div {
    background-image: url("./assets/images/BGDelete.png");
    background-position: center;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .legal_h3 {
    color: var(--text-light-black);
    font-family: UrbanistReg;
    font-size: 24px;
  }
}
/* ___________________________________________ */
/* Terms Page */
.terms_main_div {
  background-image: url("./assets/images/BGPrivacy.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.terms1_main_div {
  background-image: url("./assets/images/BGLegal.png");
  background-position: center;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}
/* ___________________________________________ */
/* Certification Page */
.certi_main_div {
  background-image: url("./assets/images/BGAbout1.png");
  /* background-position: center; */
  /* width: 100%; */
  background-size: contain;
  background-repeat: no-repeat;
}
/* ___________________________________________ */
/* Privacy Page */
.privacy_main_div {
  background-image: url("./assets/images/BGPrivacy.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 576px) {
  .privacy_main_div {
    background-image: url("./assets/images/BGDelete.png");
    background-position: center;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .terms_main_div {
    background-image: url("./assets/images/BGDelete.png");
    background-position: center;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
/* ___________________________________________ */
/* Certification Section */
.certification_back {
  background-image: url("./assets/images/BGPrivacy.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
/* ______________________________________________________ */
/* Careers Section */

/* ______________________________________________________ */
/* Legal Page */
.legal1_main_div {
  background-image: url("./assets/images/BGLegal.png");
  background-position: center;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}
.jobsCard {
  /* border: 1.5px solid #e2e2e2 !important; */
  border: 1.5px solid #b6b6b6 !important;
  background-color: #f7fcff;
}
.jobsCard:hover {
  box-shadow: 0px 14.800000190734863px 48.75777816772461px 0px #00000012;
  border: 1.5px solid #f7fcff !important;
  background-color: #fff;
}
.divider2 {
  height: 2px;
  background-color: #0176db;
  width: 20px;
}
.careersHero {
  background-color: #e7f2ff;
}
/* _____________________________________ */
/* Careers Detail Page */
.careers_h3 {
  color: #0a76db;
  font-family: "UrbanistReg";
  font-size: 22px;
}
.careers_h3.careerDetailHeading {
  color: #0a76db;
  font-family: "UrbanistBold";
  font-size: 30px;
}
.career_btn {
  padding: 6px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 30px;
  background-color: #0a76db;
  font-family: ManropeBold;
  color: white;
  border: 2px solid #0a76db;
  box-shadow: 0px 19px 26px -9px #0000002b;
}
.career_btn:hover {
  color: #0a76db;
  border: 2px solid #0a76db;
  background-color: white;
}

.careerSubHeadingFontSize {
  font-size: 18px !important;
}
.careerMobileHeadingBorder {
  /* border-bottom: 2px solid #0176db; */
  margin-bottom: 2px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.CareerDetailMobileViewLine{
  background-color: #0176db;
  padding: 1.3px;
  border-radius: 18px;
  width: 65px;
}
.careers_h3_1 {
  color: #2b2b2b;
  font-family: "ManropeSemiBold" !important;
  font-size: 25px;
}
.careers_h3_105 {
  color: #2b2b2b;
  font-family: "ManropeSemiBold" !important;
  font-size: 25px;
}
.career_main_box {
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */
  /* box-shadow: 0px 14.800000190734863px 48.75777816772461px 0px #00000012; */
  box-shadow: 0px 4px 22px 0px #00000017;

  background-color: #fff;
}
.career_p_1 {
  font-family: "UrbanistBold";
  color: #0a76db;
  font-size: 22px;
}
.career_p_2 {
  font-family: "ManropeRegular";
  color: #2b2b2b;
  font-size: 11px;
}
.careers_h3_2 {
  color: #2b2b2b;
  font-family: UrbanistBold;
  font-size: 17px;
}
.border_right_career {
  border-right: 2px solid #f1f1f1;
}
.navbar-nav.first .nav-link:hover .ChevronDown {
  color: var(--navbar-active-color) !important;
}
.circle_career:hover {
  background-color: #0176db;
}
.circle_career {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2b2b2b;
  color: #2b2b2b;
}
.circle_career1 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2b2b2b;
  color: #2b2b2b;
}
.inactiveBtn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: transparent;
  color: #2b2b2b;
}
.activeBtn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: transparent;
  background-color: #0176db;
}
.career2_main_div {
  background-image: url("./assets/images/BGCareer2.jpeg");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.career_main_div {
  background-image: url("./assets/images/BGCareer2.jpeg");
  background-position: right;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.btn_carrers {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
@media screen and (max-width: 576px) {
  .btn_carrers {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .careers_h3.careerDetailHeading {
    color: #0a76db;
    font-family: "UrbanistBold";
    font-size: 22px !important;
  }
  .careerSubHeadingFontSize {
    font-size: 14px !important;
  }
}
/* ________________________________________ */
/* Delete Page */
.delete_main_div {
  background-image: url("./assets/images/BGPrivacy.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.form_control_delete {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  /* font-size: 1rem; */
  font-weight: 400;
  /* line-height: 1.5; */
  color: var(--bs-body-color);
  appearance: none;
  background-clip: padding-box;
  border-radius: var(--bs-border-radius);
  background-color: #f7f7f7 !important;
  border: 2px solid #ebebeb;
  border-radius: 10px;
  outline: transparent;
}
.form-select {
  background-color: #f7f7f7 !important;
  border: 2px solid #ebebeb;
}
.form_textarea_delete {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  /* font-size: 1rem; */
  font-weight: 400;
  /* line-height: 1.5; */
  color: var(--bs-body-color);
  appearance: none;
  background-clip: padding-box;
  background-color: #f7f7f7 !important;
  border: 2px solid #ebebeb;
  border-radius: 10px;
  padding-left: 15px !important;
  padding-top: 10px !important;
}
.form_textarea_delete::placeholder {
  font-size: 12px;
}
.form_textarea_delete:focus {
  display: block;
  outline: none !important;
  width: 100%;
  padding: 0.375rem 0.75rem;
  /* font-size: 1rem; */
  font-weight: 400;
  /* line-height: 1.5; */
  color: var(--bs-body-color);
  appearance: none;
  background-clip: padding-box;
  background-color: #f7f7f7 !important;
  border: 2px solid #0176db;
  border-radius: 10px;
  padding-left: 15px !important;
  padding-top: 10px !important;
}
.dele_btn {
  padding: 10px;
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 30px;
  background-color: #0a76db;
  font-family: ManropeBold;
  /* line-height: 24.59px; */
  color: white;
  border: 2px solid #0a76db;
}
.dele_btn:hover {
  padding: 10px;
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 30px;
  color: #0a76db;
  border: 2px solid #0a76db;
  font-family: ManropeBold;
  /* line-height: 24.59px; */
  background-color: white;
}
.deletePage_h3 {
  font-size: 30px;
  font-weight: 300;
  max-width: 30% !important;
  font-family: "UrbanistReg";
}
@media screen and (max-width: 768px) {
  .deletePage_h3 {
    font-size: 30px;
    font-weight: 300;
    max-width: 50% !important;
    font-family: "UrbanistReg";
  }
}
@media screen and (max-width: 576px) {
  .delete_main_div {
    background-image: url("./assets/images/BGDelete.png");
    background-position: center;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .dele_btn {
    font-size: 13px !important;
    padding: 10px 25px !important;
  }
  .frameWrapper {
    display: none;
  }
  .frameWrapper2 {
    display: none;
  }
  .deletePage_h3 {
    font-size: 16px;
    max-width: 100% !important;
    font-family: "UrbanistReg";
  }
  .googleMapLocation {
    width: 100%;
    height: 250px !important;
    position: relative;
  }
  .careers_h3.careerDetailHeading {
    font-weight: 700;
    text-align: center;
  }
  .career_btn {
    text-align: center;
  }
  .career_btn.careerDetails {
    /* padding: 10px; */
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    background-color: #0085e6 !important;
    font-family: ManropeBold;
    font-size: 13px !important;
    color: white;
    border: 2px solid #0085e6 !important;
    box-shadow: 0px 19px 26px -9px #0000002b;
  }
  .carrierDetails {
    background-image: none !important;
  }
  .aboutTheJobCareerDetail {
    background-image: url("./assets/images/BGCareerMobileScreen.png");
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: 39px;
    width: 100%;
    background-size: 20% 10% !important;
    background-repeat: no-repeat;
  }
  .sliderOtherPositions {
    background-image: none !important;
  }
  .borderTopShareJob {
    border-top: 2px solid #f1f1f1;
  }
  .career_p_1 {
    font-family: "UrbanistSemiBold";
    color: #0a76db;
    font-size: 22px;
  }
  .jobsCard.careerDetailotherPositions {
    height: 230px !important;
  }
  .pragraph_Sty_para.homeScreen {
    font-size: 9px !important;
    font-weight: 500;
    font-family: "ManropeRegular";
  }
}

/* .delete_foot {
  margin-top: 10%;
} */
.contact_page_foot {
  margin-top: 20%;
}
@media only screen and (min-width: 768px) and (max-width: 792px) {
  .deletePage_h3 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 1200px) {
  /* .delete_foot {
    margin-top: 30%;
  } */
  .deleteMydataForm {
    margin-top: 0%;
    width: 100%;
    position: absolute;
    /* top: 35%; */
  }
  .contact_page_foot {
    margin-top: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .deleteMydataForm {
    margin-top: 3%;
    width: 100%;
    position: absolute;
  }
}
@media (max-width: 767px) {
  .delete_foot {
    margin-top: 190px;
  }
  .deleteMydataForm {
    width: 100%;
    position: absolute;
    top: 25%;
  }
  .contact_page_foot {
    margin-top: 140%;
  }
  .border_right_career {
    border-right: none;
  }
}

@media only screen and (max-width: 576px) {
  .delete_foot {
    /* Margin for screens up to 576px */
    margin-top: 89px !important;
  }
  .PersonalDataInDeleteMyData {
    margin: 7% 0px !important;
  }
  .deleteMydataForm {
    width: 100%;
    position: absolute;
    top: 14%;
  }
  .contact_page_foot {
    margin-top: 10%;
  }
}

/* ____________________________________ */
@media screen and (max-width: 769px) {
  .carousel-control-next {
    display: none;
  }
  .carousel-control-prev {
    display: none;
  }
  .carousel-indicators {
    display: none;
  }
}

.mobileLogoScreen {
  width: 30% !important;
  height: 30px;
}

.allbrandlogo {
  width: 100% !important;
  height: auto;
}
@media (max-width: 768px) {
  .allbrandlogo {
    width: 90% !important;
    height: auto;
  }
}

@media (min-width: 576px) {
  .disp_text {
    display: none;
  }
}
@media (min-width: 769px) {
  .left_right_mobile {
    display: none;
  }
}
.modal.fade.show {
  background-color: transparent !important;
}

/* Umer CSS */
.searchIcon{
  color: var( --text-light-gray) !important;
}
.countryCodeSeleted{
  font-weight: 400 !important;
  font-size: 14px !important;
  font-weight: normal !important;
}

/* .selectCountry.main_number_input input{
  color: var(--text-light-gray) !important;
} */
.selectCountry.main_number_input input::placeholder{
  color: var(--text-light-gray) !important;
}
/* Umer CSS */

.modal-backdrop.show {
  opacity: 0 !important;
}
.cursor_pointer {
  cursor: pointer;
}
/* @media (min-width: 1400px) {
  .modal_content_nav {
    margin-left: -70px !important;
  }
} */
/* @media only screen and (min-width: 992px) and (max-width: 1200px) {
  .modal_content_nav {
    margin-left: 60px !important;
  }
}
@media (max-width: 576px) {
  .modal_content_nav {
    width: 80% !important;
    margin-left: 10% !important;
  }
} */

/* __________________________________________ */
/* Team Section */

.Our_Team_box {
  border: 6px solid#EEEEEE;
  position: relative;
  border-radius: 14px;
  box-shadow: 0px 18px 59.29999923706055px 0px #00000026;
  height: 100%;
}
.team_images {
  width: 100%;
  height: auto;
}
.Team_Details_box {
  position: absolute;
  background-color: white;
  border-radius: 6px;
  width: 90%;
  bottom: 5%;
  left: 5%;
}

.Team_Details_box h6 {
  font-family: UrbanistBold !important;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 16px;
}

.Team_Details_box p {
  font-family: ManropeBold !important;
  text-align: center;
  font-size: 9px;
  margin-bottom: 0px;
  font-weight: 600;
  color: #06558c;
}

.heading_Sty_har {
  font-size: 35px;
  font-family: UrbanistReg;
  font-weight: 300;
  width: 75%;
  /* line-height: 38px; */
}

.pragraph_Sty_para {
  font-size: 15px;
  font-weight: 500;
  font-family: "ManropeRegular";
}

.swati_Icon_bg {
  position: absolute;
  left: 2%;
  width: 70%;
}
.pragraphSty.whyChoose {
  font-size: 13px !important;
  font-family: ManropeRegular;
  font-weight: 500;
}
.homeFeature_p_2 {
  font-family: Questrial;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
}
.homeFeature_p_8 {
  font-family: ManropeRegular;
  font-size: 9.5px;
  height: 50px;
  font-weight: 300;
  color: #a3a3a3;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .contactpage_p_1 {
    font-size: 9.8px !important;
  }
}

@media (min-width: 2000px) {
  .Team_Details_box p {
    font-size: 16px;
  }
  .Team_Details_box h6 {
    font-size: 26px;
  }
  .swati_Icon_bg {
    width: 80%;
  }
  .heading_Sty_har {
    font-size: 20px;
    font-family: UrbanistReg;
    font-weight: 300;
    width: 100%;
    /* line-height: 28px; */
  }
}
@media (max-width: 1024px) {
  .Team_Details_box p {
    font-size: 7px;
  }
  .Team_Details_box h6 {
    font-size: 13px;
  }
}
@media (max-width: 1024px) {
  .swati_Icon_bg {
    width: 100%;
  }
  .heading_Sty_har {
    font-size: 20px;
    font-family: UrbanistReg;
    font-weight: 300;
    width: 100%;
    /* line-height: 28px; */
  }
}
@media (max-width: 768px) {
  .swati_Icon_bg {
    width: 100%;
  }
  .heading_Sty_har {
    font-size: 20px;
    font-family: UrbanistReg;
    font-weight: 300;
    width: 100%;
    /* line-height: 28px; */
  }
}
@media (max-width: 576px) {
  .Team_Details_box {
    border-radius: 6px;
  }
  .Team_Details_box h6 {
    font-size: 11px;
  }

  .Team_Details_box p {
    font-size: 6px;
  }
  .swati_Icon_bg {
    width: 80%;
    position: absolute;
    left: 18%;
    width: 70%;
  }
  .heading2Sty.homePage {
    font-size: 16px !important;
    font-family: "UrbanistSemiBold" !important;
  }
  .pragraphSty.Innovation,
  .pragraphSty.transparency,
  .pragraphSty.whyChoose {
    font-size: 11px !important;
  }

  .heading_Sty_har {
    font-size: 17px !important;
    font-family: UrbanistReg;
    font-weight: 300;
    width: 100%;
    text-align: center !important;
    /* line-height: 28px; */
  }
}
@media (max-width: 335px) {
  .pragraphSty.whyChoose {
    font-size: 9px !important;
  }
}
/* _____________Add Dropdown Custom Styling_________ */
/* .dropdown-menu.dropdown_products {
  width: 700%;
  left: -223%;
  background-color: transparent;
  border: 1px solid transparent;
  padding-top: 0px;
} */
/* .dropdown-menu.dropdown_services {
  width: 700%;
  left: -320%;
  background-color: transparent;
  border: 1px solid transparent;
  padding-top: 0px;
} */
@media (min-width: 1201px) and (max-width: 1440px) {
  /* .dropdown-menu.dropdown_products {
    left: -190% !important;
  } */
  /* .dropdown-menu.dropdown_services {
    left: -314.5%;
  } */
  .heading_Sty_har {
    font-size: 25px;
  }
  .background_element_img {
    background-size: contain;
  }
}
@media (max-width: 1200px) {
  /* .dropdown-menu.dropdown_products {
    left: -259% !important;
  }
  .dropdown-menu.dropdown_services {
    left: -390%;
  } */
  .heading_Sty_har {
    font-size: 23px;
  }
  .background_element_img {
    background-size: contain;
  }
}
.navbar-nav .nav-item:hover .dropdown-menu {
  display: block;
}
/* .modal_dialog {
  margin-top: 20px !important;
} */
/* ___________________________________________ */
/* Estimate Page */
.estimate_h3 {
  font-size: 34px;
  font-weight: 300;
  font-family: "UrbanistReg";
  /* line-height: 38px; */
  color: #2b2b2b;
  width: 75%;
}
.estimate_main_box {
  margin-left: 5%;
}
.estimate_main_div {
  background-image: url("./assets/images/BGEstimate.png");
  background-position: center;
  background-size: cover;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
}
.estimate_btn {
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 30px;
  background-color: #0a76db;
  font-family: ManropeBold;
  /* line-height: 24.59px; */
  color: white;
  border: 2px solid #0a76db;
}
.estimate_btn:hover {
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 30px;
  color: #0a76db;
  border: 2px solid #0a76db;
  font-family: ManropeBold !important;
  /* line-height: 24.59px; */
  background-color: white;
}
.esti_input input::placeholder {
  font-size: 12px;
  font-family: ManropeRegular;
}
.esti_input input {
  font-size: 12px;
  font-family: ManropeRegular;
}
.esti_input textarea {
  font-size: 12px;
  font-family: ManropeRegular;
}
.esti_input label {
  font-family: UrbanistBold !important;
}
.esti_input select {
  font-size: 12px;
  font-family: ManropeRegular;
  /* font-family: UrbanistBold; */
}
.form-check .form-check-input1 {
  border-radius: 50px;
  width: 20px;
  height: 20px;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 2px solid #2b2b2b;
}
.form-check .form-check-input1:checked {
  border-radius: 50px !important;
  width: 20px !important;
  height: 20px !important;
  margin-top: 0.25em !important;
  vertical-align: top !important;
  appearance: none !important;
  background-color: #0176db !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  border: 2px solid #0176db !important;
}
.form-check .form-check-input1:focus {
  border-radius: 50px !important;
  width: 20px !important;
  height: 20px !important;
  margin-top: 0.25em !important;
  vertical-align: top !important;
  appearance: none !important;
  background-color: #0176db !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  border: 2px solid #0176db !important;
}
.select_est {
  width: 45%;
  font-family: ManropeRegular;
}
.form_select_estimate {
  padding: 0.65rem 0.75rem !important;
  background-color: #f7f7f7 !important;
  border: 2px solid #ebebeb;
  border-radius: 10px;
}
.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.form-group label {
  position: relative;
  cursor: pointer;
}
.form-group label:before {
  content: "";
  width: 16px !important;
  height: 16px !important;
  background-color: transparent;
  border: 1.5px solid #000000;
  padding: 8px;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}
.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 7px;
  width: 5px;
  height: 10px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.estimat_Form {
  /* font-family: ManropeRegular; */
  position: relative;
  background-color: #fdfdfd;
  border-radius: 20px;
  padding: 20px 20px;
  box-shadow: 0px 14.800000190734863px 48.75777816772461px 0px #0000001a;
  z-index: 12;
  margin-top: -25%;
}
.textColorYesAndNo {
  color: #929292;
  font-size: 15px !important;
}
@media (min-width: 1800px) {
  .estimat_Form {
    z-index: 12;
    margin-top: -16%;
    padding: 8px 8px;
  }
}
@media (min-width: 787px) and (max-width: 993px) {
  .estimat_Form {
    z-index: 12;
    margin-top: 5%;
    padding: 8px 8px;
  }
}
@media (max-width: 993px) {
  .estimate_main_box {
    margin-left: 0%;
  }
}
@media (max-width: 1200px) {
  .estimate_btn {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 768px) {
  .estimat_Form {
    z-index: 12;
    margin-top: 5%;
    padding: 8px 8px;
  }
}
@media (max-width: 576px) {
  .estimat_Form {
    z-index: 12;
    margin-top: 5%;
    padding: 8px 8px;
  }
  .estimate_h3 {
    font-size: 20px;
    width: 100%;
    /* line-height: 25px; */
  }
  .textColorYesAndNo {
    color: #929292 !important;
    font-size: 8px !important;
  }
  .form-group label:before {
    content: "";
    width: 16px !important;
    height: 16px !important;
    background-color: transparent;
    border: 1.5px solid #929292 !important;
    padding: 8px;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
}
.dropdownNumberApp {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  background-color: #f7f7f7 !important;
  display: flex;
  align-items: center;
  border: 2px solid #ebebeb;
  border-radius: 12px;
}
.colorNumber {
  color: #2b2b2b !important;
  font-size: 14px;
  font-family: ManropeRegular !important;
  /* font-weight: 400; */
}
.colorNumber2 {
  color: #2b2b2b !important;
  font-size: 12px;
  font-family: ManropeRegular !important;
}
/* .listNumberApp:hover {
  background-color: #0a76db;
  border-radius: 10px;
  color: white;
} */

.dropdownMenuNumberApp {
  padding: 5px;
  height: 150px !important;
  overflow-y: auto;
  width: 204px;
  background-color: #FDFDFD;
}
.dropdown_menu_margin {
  margin-top: 14% !important;
  margin-left: -83% !important;
}
/* Dropdown of Contact page for Country Code start */
.dropdown-menu.dropdown_menu_margin{
  min-width: 20px !important;
}
.dropdown-menu.dropdown_menu_margin.dropdownMenuNumberApp.estimate{
  position: absolute;
  left: 60% !important;
}
.dropdown-item.listNumberApp {
  padding: 5px !important;
  border-bottom: 1px solid #BABABA;
}
/* Dropdown of Contact page for Country Code end*/
.dropdown-toggle1::after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23666666%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 5px;
  vertical-align: middle;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
}
.listselectservices {
  font-size: 12px !important;
}
/* ___________________________________________ */
.navbar-nav.first .nav-link:hover .ChevronDown {
  color: var(--navbar-active-color) !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.border_bottom_line {
  border-bottom: 3px solid rgba(232, 232, 232, 0.6) !important;
}
