@font-face {
  font-family: "RalewayMedium";
  src: url("../../assets/fonts/Raleway-Medium.ttf");
}
@font-face {
  font-family: "RalewaySemiBold";
  src: url("../../assets/fonts/Raleway-SemiBold.ttf");
}
@font-face {
  font-family: "Questrial";
  src: url("../../assets/fonts/Questrial-Regular.ttf");
}
@font-face {
  font-family: "BebasNeue";
  src: url("../../assets/fonts/BebasNeue-Regular.ttf");
}
@font-face {
  font-family: "RalewayRegular";
  src: url("../../assets/fonts/Raleway-Regular.ttf");
}
@font-face {
  font-family: "InterLight";
  src: url("../../assets/fonts/Inter-Light.ttf");
}
/* Navbar Section */
.navbar-nav.second .nav-link {
  font-size: 12px;
  line-height: 24.59px;
  color: var(--text-light-black);
  font-family: "ManropeRegular";
  margin-left: 10px;
  text-transform: uppercase;
}
.navbar-nav.second .nav-item.dropdown .dropdown-menu .dropdown-item {
  font-size: 18px;
  line-height: 24.59px;
  color: var(--text-light-black);
  font-family: "ManropeRegular";
}
/* Active link color */
.navbar-nav.second .nav-link.active {
  color: var(--navbar-active-color);
  font-family: "ManropeRegular";
}
.navbar-nav.second .nav-link:hover {
  color: var(--navbar-active-color);
  font-family: "ManropeRegular";
}
/* Active dropdown link color */
.navbar-nav.second .nav-item.dropdown .dropdown-menu .dropdown-item.active,
.navbar-nav.second .nav-item.dropdown .dropdown-menu .dropdown-item:hover {
  color: var(--navbar-active-color);
  font-family: ManropeExtraBold;
}
.nav_border {
  border-bottom: 1px solid #424242b0;
}
.modal_navbar_p_product {
  color: var(--navbar-link-color);
  line-height: 17.59px !important;
  font-size: 11px;
  font-family: "ManropeRegular";
}
.modal_body_product {
  padding: 0 !important;
}
.modal_navbar_box_product {
  background: #03080e;
  border: 1px solid #0a76db9c;
  cursor: pointer;
  padding: 15px !important;
}
.modal_navbar_box_product:hover {
  outline: 2px solid #0a76db;
  background-color: #111721;
}
.modal_content_nav_product {
  border: 1px solid var();
  background-color: transparent;
  margin-left: -25px;
}
.modal_navbar_p1_product {
  font-size: 13px;
}
.atten_shadow_div {
  background: #fff !important;
  box-shadow: 0px 20px 52.289px 0px rgba(68, 68, 68, 0.04) !important;
}
/* ________________________________________ */
.attendify_box2 {
  margin-left: 32%;
}
.attendify_h1 {
  font-family: MerriweatherBold;
  font-weight: 700;
  font-size: 36.38px;
  line-height: 53.75px;
  color: #111111;
}
.attendify_h1_1 {
  font-family: MerriweatherBold;
  font-weight: 700;
  font-size: 36.38px;
  background: var(
    --gradient,
    linear-gradient(256deg, #639eff 0%, #0056e2 103.13%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.attendify_box3 {
  position: relative;
  height: 510px;
}
.RectangleFrameFirst {
  top: 80%;
  left: 90%;
}
.RectangleFrameSecond {
  top: 80%;
  left: -2%;
}
.attendify_h1_2 {
  font-family: MerriweatherBold;
  font-weight: 700;
  font-size: 34.38px;
  line-height: 40px;
  width: 67%;
  color: #111111;
}
.attendify_p {
  font-family: RalewayMedium;
  font-weight: 500;
  font-size: 14px;
  color: #2b2b2b;
  width: 80%;
}
.attendify_box4 {
  position: relative;
  height: 520px;
}
.RectangleFrameThird {
  top: 6%;
  left: -1%;
}
.RectangleFrameFourth {
  top: 48%;
  left: -3%;
}
.RectangleFrameFifth {
  top: 37%;
  right: -1%;
}
.attendify_h1_3 {
  font-family: MerriweatherBold;
  font-weight: 700;
  font-size: 34.38px;
  line-height: 40px;
  width: 75%;
}
.attendify_p_1 {
  font-family: RalewayMedium;
  font-weight: 500;
  color: #2b2b2b;
  font-size: 14px;
  line-height: 23.27px;
  width: 100%;
}
.RectangleFrameSixth {
  top: 10%;
  left: 45%;
}
.AttendifyFrame7 {
  top: 72%;
  left: -1% !important;
}
.attendify_h1_4 {
  font-family: MerriweatherBold;
  font-weight: 700;
  font-size: 34.38px;
  line-height: 48px;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
}
.attendify_p_2 {
  font-family: RalewayMedium;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  line-height: 23.27px;
  color: #2b2b2b;
  padding-left: 15px;
  padding-right: 15px;
}
.attendify_p_3 {
  font-family: RalewaySemiBold;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #111111;
}
.attendify_box5 {
  position: relative;
  height: 280px;
}
.RectangleFrameSeventh {
  top: 7%;
  left: 49%;
  z-index: -1;
}
.attendify_h5 {
  font-family: RalewaySemiBold;
  font-weight: 600;
  font-size: 19px;
  color: #383838;
}
.attendify_p_4 {
  font-family: RalewayMedium !important;
  font-weight: 500 !important;
  font-size: 14px;
  color: #2b2b2b;
}
.smallBox_1 {
  position: relative;
  height: 390px;
}
.RectangleFrameEleven {
  top: 80%;
  left: -8%;
}
.smallBox_2 {
  position: relative;
  height: 200px;
}
.smallBox_3 {
  position: relative;
  height: 200px;
}
.smallBox_4 {
  position: relative;
  height: 200px;
}
.RectangleFrameNineth {
  top: 8%;
  left: 53%;
}
.LinesIcons {
  width: 100px;
}
.attendify_h1_5 {
  font-family: MerriweatherBold;
  font-weight: 700;
  font-size: 34.38px;
  line-height: 40px;
  width: 70%;
}
.attendify_box6 {
  height: 430px;
}
.attendify_box7 {
  background-color: #5893ff14;
}
#background-video1 {
  width: 100%;
  height: 100%;
}
.attendify_h1_6 {
  font-family: MerriweatherBold;
  font-weight: 700;
  font-size: 34.38px;
  line-height: 40px;
  width: 30%;
  color: #212121;
}
.attendify_p_5 {
  font-family: RalewayMedium;
  font-weight: 500;
  font-size: 14px;
  line-height: 23.27px;
  color: #2b2b2b;
  width: 40%;
}
.attendify_box8 {
  position: relative;
  height: 590px;
}
.attendify_box9 {
  margin-left: 17%;
  margin-top: 7%;
}
.AttendifyFrame15_img {
  width: 96% !important;
}
.AttendifyFrame15 {
  top: 40%;
  left: 40%;
}
.RectangleFrameFourteen {
  top: 15%;
  left: 74%;
}
.RectangleFrameFifteen {
  top: 69%;
  left: -2%;
}
.RectangleFrameSixteen {
  top: 9%;
  right: -2%;
}
.RectangleFrameSeventeen {
  top: 85%;
  left: 36%;
}
.RectangleFrameEighteen {
  top: 25%;
  left: -2%;
}
.attendify_p_6 {
  font-family: RalewayMedium;
  font-weight: 500;
  color: #2b2b2b;
  font-size: 14px;
  line-height: 23.27px;
  width: 86%;
}
.attendify_box10 {
  margin-left: 29%;
}
.shadow_Attendify {
  box-shadow: 0px 20px 52.29px 0px #4444440a;
  height: 28px;
}
.attendify_working_heading {
  box-shadow: 0px 4px 25px 0px #3c50e057;
}
.Attendify_download_icon {
  /* box-shadow: 0px 2.73px 4.64px 0px #9672ff04 !important; */
  box-shadow: 0px 100px 80px 0px rgba(82,146,250, 0.07),
    0px 64.815px 46.852px 0px rgba(82,146,250, 0.05),
    0px 38.519px 25.481px 0px rgba(82,146,250, 0.04),
    0px 20px 13px 0px rgba(82,146,250, 0.04),
    0px 8.148px 6.519px 0px rgba(82,146,250, 0.03),
    0px 1.852px 3.148px 0px rgba(82,146,250, 0.02) !important;
}
.Attendify_signUp_icon {
  /* box-shadow: 0px 2.73px 4.64px 0px #9672ff04 !important; */
  box-shadow: 0px 147.273px 117.818px 0px rgba(150, 114, 255, 0.07),
    0px 95.455px 69px 0px rgba(150, 114, 255, 0.05),
    0px 56.727px 37.527px 0px rgba(150, 114, 255, 0.04),
    0px 29.455px 19.145px 0px rgba(150, 114, 255, 0.04),
    0px 12px 9.6px 0px rgba(150, 114, 255, 0.03),
    0px 2.727px 4.636px 0px rgba(150, 114, 255, 0.02) !important;
}
.Attendify_MarkAttendence_icon {
  /* box-shadow: 0px 1.85px 3.15px 0px #f2b8ec04 !important; */
  box-shadow: 0px 100px 80px 0px rgba(242, 184, 236, 0.07),
    0px 64.815px 46.852px 0px rgba(242, 184, 236, 0.05),
    0px 38.519px 25.481px 0px rgba(242, 184, 236, 0.04),
    0px 20px 13px 0px rgba(242, 184, 236, 0.04),
    0px 8.148px 6.519px 0px rgba(242, 184, 236, 0.03),
    0px 1.852px 3.148px 0px rgba(242, 184, 236, 0.02) !important;
}
.Attendify_trackAttendance_icon {
  /* box-shadow: 0px 147.27px 117.82px 0px #9672ff12 !important; */
  box-shadow: 0px 147.273px 117.818px 0px rgba(150, 114, 255, 0.07),
    0px 95.455px 69px 0px rgba(150, 114, 255, 0.05),
    0px 56.727px 37.527px 0px rgba(150, 114, 255, 0.04),
    0px 29.455px 19.145px 0px rgba(150, 114, 255, 0.04),
    0px 12px 9.6px 0px rgba(150, 114, 255, 0.03),
    0px 2.727px 4.636px 0px rgba(150, 114, 255, 0.02) !important;
}
.AppleAppStore,
.googlePlayStore {
  box-shadow: 0px 29.64px 29.89px -4px rgba(0, 0, 0, 0.26);
}
#ATEN-video1 {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 1700px) and (max-width: 2600px) {
  .attendify_box8 {
    height: 900px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1150px) {
  .AttendifyFrame15 {
    top: 39%;
    left: 38%;
  }
  .AttendifyFrame15_img {
    width: 100% !important;
  }
}
@media screen and (max-width: 1200px) {
  .attendify_h1_4 {
    font-size: 30.38px !important;
    padding-left: 20px;
    padding-right: 20px;
  }
  .LinesIcons {
    display: none;
  }
  .attendify_h5 {
    font-size: 14px;
  }
  .attendify_p_4 {
    font-size: 12px;
  }
}
@media screen and (max-width: 1024px) {
  .attendify_h1_4 {
    font-size: 30.38px !important;
  }
  .attendify_p_2 {
    font-size: 12.54px;
    line-height: 23.27px;
    color: #2b2b2b;
    padding-left: 20px;
    padding-right: 20px;
  }
  .RectangleFrameEleven {
    display: none;
  }
  .smallBox_1 {
    height: 200px;
  }
  .smallBox_2 {
    height: 200px;
  }
  .smallBox_3 {
    height: 200px;
  }
  .smallBox_4 {
    height: 200px;
  }
  .RectangleFrameFifteen {
    display: none;
  }
  .RectangleFrameFourteen {
    top: 35%;
    left: 66%;
  }
  .attendify_h1_6 {
    font-size: 34.38px;
    line-height: 40px;
    width: 50%;
  }
  .attendify_p_5 {
    font-size: 11.54px;
    line-height: 23.27px;
    color: #2b2b2b;
    width: 54%;
  }
  .attendify_box8 {
    position: relative;
    height: 700px;
  }
  .AttendifyFrame15 {
    top: 55%;
    left: 5%;
  }
}
@media screen and (max-width: 768px) {
  .attendify_box2 {
    margin-left: 5%;
    margin-top: 15%;
  }
  .attendify_box10 {
    margin-left: 5%;
    margin-top: 15%;
  }
  .attendify_h1 {
    font-size: 25.38px;
    line-height: 33.75px;
  }
  .attendify_h1_1 {
    font-size: 25.38px;
    line-height: 33.75px;
  }
  .attendify_box3 {
    position: relative;
    height: auto;
  }
  .RectangleFrameFirst {
    top: 86% !important;
    left: 86% !important;
  }
  .RectangleFrameSecond {
    display: none;
  }
  .attendify_h1_2 {
    font-size: 26.38px;
    line-height: 40px;
    width: 100%;
    margin-top: 5%;
  }
  .attendify_p {
    font-size: 10.54px;
    line-height: 20.27px;
    width: 100%;
  }
  .attendify_box4 {
    position: relative;
    height: auto;
  }
  .RectangleFrameThird {
    display: none;
  }
  .RectangleFrameFourth {
    top: 72%;
    left: -3%;
  }
  .RectangleFrameFifth {
    display: none;
  }
  .attendify_h1_3 {
    font-size: 26.38px;
    line-height: 35px;
    width: 100%;
  }
  .attendify_p_1 {
    font-size: 12.54px;
    line-height: 20.27px;
    color: #2b2b2b;
    width: 100%;
  }
  .attendify_h1_4 {
    font-size: 24.38px !important;
    line-height: 28px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .attendify_p_2 {
    font-size: 10px;
    line-height: 18.27px;
    color: #2b2b2b;
    padding-left: 0px;
    padding-right: 0px;
  }
  .AttendifyFrame7 {
    display: none;
  }
  .attendify_h1_5 {
    font-size: 26.38px;
    line-height: 35px;
    width: 100%;
  }
  .attendify_h1_6 {
    font-size: 26.38px;
    line-height: 35px;
    width: 70%;
  }
  .attendify_p_5 {
    font-size: 12.54px;
    line-height: 20px;
    color: #2b2b2b;
    width: 70%;
  }
  .RectangleFrameFourteen {
    display: none;
  }
  .AttendifyFrame15 {
    top: 47%;
    left: 5%;
  }
  .attendify_box8 {
    position: relative;
    height: 580px;
  }
}
@media screen and (max-width: 576px) {
  .attendify_box2 {
    margin-left: 0%;
    margin-top: 15%;
  }
  .attendify_box10 {
    margin-left: 5%;
    margin-top: 15%;
  }
  .attendify_h1 {
    font-size: 25.38px;
    line-height: 33.75px;
  }
  .attendify_h1_1 {
    font-size: 25.38px;
    line-height: 33.75px;
  }
  .RectangleFrameFirst {
    top: 66% !important;
    left: 80% !important;
  }
  .attendify_h1_2 {
    font-size: 20.38px;
    line-height: 30px;
    width: 100%;
    margin-top: 5%;
  }
  .attendify_p {
    font-size: 10.54px;
    line-height: 20.27px;
    width: 100%;
  }
  .RectangleFrameFourth {
    top: 64%;
    left: -3%;
  }
  .attendify_h1_3 {
    font-size: 23.38px;
    line-height: 30px;
  }
  .attendify_p_1 {
    font-size: 11.54px;
    line-height: 18.27px;
    color: #2b2b2b;
    width: 100%;
  }
  .attendify_h1_4 {
    font-size: 20.38px !important;
    line-height: 28px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .attendify_box6 {
    height: 780px;
  }
  .attendify_p_5 {
    font-size: 11px;
    line-height: 15px;
    color: #2b2b2b;
    width: 90%;
  }
  .AttendifyFrame15 {
    top: 50%;
    left: 5%;
  }
  .attendify_box8 {
    height: 330px;
  }
  .attendify_h1_6 {
    font-size: 20px;
    line-height: 30px;
    width: 90%;
  }
  .attendify_p_6 {
    width: 100%;
    font-size: 11px;
    line-height: 20px;
  }
  .RectangleFrameSeventh {
    top: 7%;
    left: 30%;
    z-index: -1;
  }
  .attendify_h1_5 {
    font-size: 21.38px;
    line-height: 31px;
    width: 100%;
  }
  .modal_content_nav_product {
    width: 80% !important;
    margin-left: 10% !important;
  }
  .RectangleFrameEighteen {
    display: none;
  }
  .RectangleFrameSixteen {
    display: none;
  }
}
/* __________________________________________ */
/* SYM PAGE CSS */
.main_div_sym {
  background-color: #060a12;
}
.main_div_sym_1 {
  background-image: url("../../assets/images/products/SYM_BG_LATEST.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center; */
  /* background-attachment: fixed; */
}
.main_div_sym_2 {
  background-image: url("../../assets/images/products/SYM_BG1.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.main_div_sym_3 {
  background-image: url("../../assets/images/products/SYM_BG20.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.main_div_sym_4 {
  background-image: url("../../assets/images/products/SYM_BG3.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.main_div_sym_5 {
  background-image: url("../../assets/images/products/SYM_BG4.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.main_div_sym_6 {
  background-image: url("../../assets/images/products/SYM_BG5.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.sym_box_1 {
  height: 510px;
}
.sym_box_2 {
  margin-left: 32%;
}
.sym_box_3 {
  margin-left: 33%;
}
.sym_box_4 {
  margin-left: 33%;
}
.sym_box_5 {
  margin-left: 16.5%;
  margin-right: 16.5%;
}
.sym_h1 {
  font-family: Questrial;
  font-weight: 400;
  font-size: 37px;
  color: #ffffff;
  /* margin-top: 10%; */
}
.sym_h1_1 {
  font-family: Questrial;
  font-size: 34px;
  width: 70%;
  font-weight: 400;
  color: #ffffff;
}
.sym_h1_2 {
  font-family: Questrial;
  font-size: 33px;
  width: 90%;
  font-weight: 400;
  color: #ffffff;
}
.sym_h1_3 {
  font-family: Questrial;
  font-size: 33px;
  text-align: center;
  font-weight: 400;
  color: #ffffff;
}
.sym_h1_4 {
  font-family: Questrial;
  font-size: 34px;
  text-align: center;
  font-weight: 400;
  color: #ffffff;
  /* margin-top: 20%; */
}
.sym_span {
  position: relative;
  display: inline-block;
  letter-spacing: 2px;
}
.sym_p {
  color: #a3a3a3;
  font-family: Roboto-Regular;
  font-size: 12px;
  /* width: 85%; */
  font-weight: 400;
}
.sym_p_1 {
  font-size: 12px;
}
.sym_p_2 {
  font-family: Questrial;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}
.sym_p_3 {
  font-family: InterLight;
  font-size: 10px;
  /* width: 85%; */
  font-weight: 300;
  color: #a3a3a3;
}
.sym_p_4 {
  color: #a3a3a3;
  font-family: Roboto-Regular;
  font-size: 13px;
  width: 70%;
  font-weight: 400;
}
.sym_p_5 {
  color: #a3a3a3;
  font-family: Roboto-Regular;
  font-size: 12px;
  width: 80%;
  font-weight: 400;
}
.sym_p_6 {
  color: #a3a3a3;
  font-family: Roboto-Regular;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
}
.sym_p_7 {
  font-family: Questrial;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}
.sym_p_8 {
  font-family: Questrial;
  font-size: 12px;
  width: 100%;
  height: 50px;
  font-weight: 400;
  color: #a3a3a3;
}
.sym_p_9 {
  font-family: Questrial;
  font-size: 18px;
  width: 100%;
  font-weight: 400;
  color: #a3a3a3;
}
.sym_span::before {
  content: "";
  position: absolute;
  border-radius: 50px;
  top: -5px; /* Adjust position if necessary */
  left: -5px; /* Adjust position if necessary */
  width: calc(100% + 10px); /* Adjust width to cover the span with a margin */
  height: calc(100% + 10px); /* Adjust height to cover the span with a margin */
  background-color: #5840ba;
  /* transform: rotate(1deg); Adjust the rotation angle as needed */
  z-index: -1; /* Ensure the pseudo-element is behind the content */
}
.sym_feature_box {
  border: 1px solid #ffffff40;
  cursor: pointer;
  border-radius: 23.645px;
}
.sym_feature_box:hover {
  background: rgba(88, 64, 186, 0.09);
  box-shadow: 35px 36px 40px 0px rgba(0, 0, 0, 0.44);
}
.scoailICONlast {
  width: 150px;
}
.SYMSeamless {
  width: 80%;
  margin-left: -3%;
}
.symDot {
  top: 21%;
  left: 95%;
}
.symDot2 {
  top: 31%;
  right: 25%;
}
.symDot3 {
  top: 60%;
  right: 13%;
}
.symDot4 {
  bottom: 5%;
  right: 65%;
}
.symDot5 {
  bottom: 13%;
  left: 13%;
}
.symDot6 {
  top: -4%;
  left: 6%;
}
.symDot7 {
  bottom: 32%;
  left: 4%;
}
.symDot8 {
  top: 14%;
  right: 6%;
}
.SymShadow {
  left: 0%;
  top: 5%;
}
.symDot1 {
  top: 68%;
  left: 15%;
}
.video1 {
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}
.content1 {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
#symVideoBtn {
  border: none;
  border: 1px solid transparent;
  background-color: transparent;
}
.sym_main_img {
  width: 57%;
}
#background-video2 {
  width: 100%;
  height: 100%;
}
.social_main {
  width: 120px;
}

@media (min-width: 769px) and (max-width: 1224px) {
  .social_main {
    width: 70px;
  }
  .sym_p_1 {
    font-size: 12px;
  }
  .sym_h1_4 {
    font-size: 30px;
  }
  .scoailICONlast {
    width: 100px;
  }
  .sym_h1 {
    font-size: 27px;
  }
}
/* @media screen and (max-width: 1340px) {
  .sym_h1 {
    font-size: 33px;
  }
} */
@media screen and (max-width: 768px) {
  .sym_main_img {
    width: 90%;
    margin-left: -7%;
  }
  .sym_main1_img {
    justify-content: center;
  }
  .sym_img_div {
    display: flex;
    justify-content: center;
  }
  .sym_box_1 {
    position: relative;
    height: auto;
  }
  .sym_box_2 {
    margin-left: 5%;
    margin-top: 15%;
  }
  .sym_h1 {
    font-size: 25.38px;
  }
  .sym_box_3 {
    margin-left: 6%;
  }
  .sym_p {
    width: 100%;
  }
  .sym_p_3 {
    font-size: 9px;
  }
  .sym_box_4 {
    margin-left: 6%;
    margin-top: 10%;
  }
  .sym_p_4 {
    font-size: 9px;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 10%;
  }
  .sym_h1_1 {
    font-size: 20px;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 16%;
  }
  .sym_h1_3 {
    font-size: 22px;
    margin-top: 15%;
  }
  .sym_h1_2 {
    font-size: 20px;
  }
  .sym_h1_4 {
    font-size: 25px;
  }
  .sym_p_5 {
    font-size: 9px;
    width: 90%;
  }
  .symDot {
    display: none;
  }
  .symDot2 {
    display: none;
  }
  .symDot3 {
    display: none;
  }
  .symDot4 {
    display: none;
  }
  .symDot5 {
    display: none;
  }
  .symDot1 {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .sym_box_2 {
    margin-left: 0%;
    margin-top: 5%;
  }
  .sym_h1 {
    font-size: 25.38px;
    line-height: 33.75px;
  }
  .SYMSeamless {
    width: 100%;
  }
  .sym_p_4 {
    font-size: 9px;
    width: 100%;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .sym_h1_1 {
    font-size: 20px;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
  }
  .sym_box_5 {
    margin-left: 10%;
    margin-right: 10%;
  }
  .scoailICONlast {
    width: 100px;
  }
  .sym_p_6 {
    font-size: 10px;
  }
}
/* __________________________________________ */
/* ACE PAGE CSS */
.main_div_ace {
  background-image: url("../../assets/images/products/ACE_BG.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fcf7ff;
}
.main_div_ace_2 {
  background-image: url("../../assets/images/products/ACE_BG_1.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
  border-radius: 21px !important;
}
.ace_box_1 {
  margin-left: 32%;
}
.ace_box_5 {
  margin-left: 30%;
}
.ace_box_51 {
  margin-right: 20%;
}
.ace_h1 {
  font-family: BebasNeue;
  font-size: 50px;
  font-weight: 400;
  color: #fe0000;
  line-height: 50px;
}
.ace_h1_1 {
  font-family: BebasNeue;
  font-size: 45px;
  font-weight: 400;
  color: #fe0000;
  line-height: 43px;
}
.ace_h1_2 {
  font-family: BebasNeue;
  font-weight: 400;
  color: #fe0000;
  line-height: 43px;
}
.ace_h4_1 {
  font-family: BebasNeue;
  font-weight: 400;
  color: #fe0000;
}
.ace_span {
  font-family: BebasNeue;
  font-weight: 400;
  color: #282828;
}
.ace_p {
  font-family: Roboto-Regular;
  font-size: 12px;
  font-weight: 400;
  color: #4b4b4b;
}
.ace_box_2 {
  margin-left: 16%;
  margin-right: 16%;
}
.ace_box_3 {
  margin-left: 25%;
}
.ace_h4 {
  font-family: BebasNeue;
  font-weight: 400;
}
.ace_p_1 {
  font-family: Roboto-Regular;
  font-weight: 400;
  color: #4b4b4b;
  font-size: 11px;
}
.ace_p_5 {
  font-family: Roboto-Regular;
  font-weight: 400;
  color: #4b4b4b;
  font-size: 10px;
  width: 68%;
}
.ACEManagementIMG {
  width: 110%;
}
.bgACE {
  background-color: #f7fcff !important;
}
.ace_box_4 {
  height: 180px;
}
.ace_p_wid {
  width: 80%;
}
@media (min-width: 768px) and (max-width: 1224px) {
  .ace_h1 {
    font-size: 35px;
    line-height: 33px;
  }
  .ace_h4 {
    font-size: 13px;
  }
  .ace_p_1 {
    font-size: 9px;
  }
}
@media screen and (max-width: 768px) {
  .ace_box_1 {
    margin-left: 5%;
    margin-top: 15%;
  }
  .ace_box_5 {
    margin-left: 5%;
    margin-top: 15%;
  }
  .ace_box_51 {
    margin-right: 0%;
  }
  .ace_box_3 {
    margin-left: 5%;
  }
  .ACEManagementIMG {
    width: 100%;
  }
  .ace_h4 {
    font-size: 13px;
  }
  .ace_p_1 {
    font-size: 9px;
  }
  .ace_p_5 {
    font-size: 9px;
    width: 85%;
  }
}
@media screen and (max-width: 576px) {
  .ace_box_1 {
    margin-left: 5%;
    margin-top: 15%;
  }
  .ace_box_5 {
    margin-left: 5%;
    margin-top: 15%;
  }
  .ace_h1 {
    font-size: 30px;
    line-height: 30px;
  }
  .ace_h1_1 {
    font-size: 22px;
    line-height: 27px;
  }
  .ace_box_3 {
    margin-left: 0%;
  }
  .main_div_ace_2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ace_box_2 {
    margin-left: 5%;
    margin-right: 5%;
  }
  .ace_p_wid {
    width: 100%;
  }
  .ace_h1_2 {
    line-height: 33px;
  }
  .ace_p {
    font-size: 10px;
  }
}
/* __________________________________________ */
/* SECURE EYE PAGE CSS */
.main_div_secure {
  background-image: url("../../assets/images/products/Secure_BG.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.main_div_eye_2 {
  background-image: url("../../assets/images/products/Secure_BG2.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.main_div_eye_3 {
  background-image: url("../../assets/images/products/Secure_BG3.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100% !important;
}
.main_div_eye_4 {
  background-image: url("../../assets/images/products/Secure_BG4.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.eye_h1 {
  font-family: Questrial;
  font-size: 44px;
  font-weight: 400;
  line-height: 46px;
}
.eye_p {
  font-family: Roboto-Regular;
  font-size: 12px;
  font-weight: 400;
  color: #a3a3a3;
}
.eye_btn {
  background-color: #00fffb;
  border: 1px solid #00fffb;
  color: #060a12;
  font-size: 12px;
  font-family: ManropeRegular;
  font-weight: 600;
}
.eye_btn:hover {
  background-color: #060a12;
  color: #00fffb;
}
.eyeBracFront {
  bottom: 90%;
  left: 0%;
}
.eyeBracBack {
  top: 90%;
  right: 0%;
}
.eyeDot {
  top: 14%;
  right: 7%;
}
.eyeDot1 {
  top: 46%;
  right: 13%;
}
.eyeDot2 {
  top: 68%;
  right: 3%;
}
.eyeDot3 {
  top: 29%;
  right: 6%;
}
.eyeDot4 {
  top: 45%;
  right: 12%;
}
.eyeDot5 {
  top: 48%;
  left: 9%;
}
.eyeDot7 {
  top: 72%;
  left: 3%;
}
.eyeDot9 {
  bottom: 13%;
  left: 39%;
}
.eyeDot10 {
  top: 65%;
  right: 38%;
}
.eye_box_3 {
  margin-left: 34%;
}
.eye_h1_1 {
  font-family: Questrial;
  font-size: 33px;
  width: 80%;
  font-weight: 400;
  color: #ffffff;
}
.eye_h1_2 {
  font-family: Questrial;
  font-weight: 400;
  color: #ffffff;
}
.eye_p_4 {
  color: #a3a3a3;
  font-family: Roboto-Regular;
  font-size: 12px;
  width: 85%;
  font-weight: 400;
}
.eye_h4 {
  font-family: Questrial;
  font-weight: 400;
  font-size: 17px;
  width: 80%;
}
.eye_p_1 {
  font-family: InterLight;
  font-weight: 300;
  color: #a3a3a3;
  font-size: 10px;
  width: 90%;
}
.eye_p_2 {
  font-family: Roboto-Regular;
  font-weight: 300;
  color: #a3a3a3;
  font-size: 12px;
}
#EYE-video1 {
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: #030014;
}
#EYE-video2 {
  width: 100%;
}
.EyeFunctionality {
  top: 0%;
}
.EyeFunctionalityImg {
  width: 65%;
}
@media screen and (max-width: 768px) {
  .eye_h1 {
    font-size: 27px;
    line-height: 35px;
  }
  .eye_h1_1 {
    font-size: 20px;
    width: 100%;
  }
  .eye_h1_2 {
    font-size: 18px;
  }
}
@media screen and (max-width: 576px) {
  .eye_h1 {
    font-size: 22px;
    line-height: 28px;
  }
  .eyeDot {
    display: none;
  }
  .eyeDot1 {
    display: none;
  }
  .eyeDot2 {
    display: none;
  }
  .eye_box_3 {
    margin-left: 5%;
  }
  .eye_h1_1 {
    font-size: 18px;
    width: 90%;
    text-align: center;
  }
  .eye_p_4 {
    font-size: 10px;
    width: 90%;
    text-align: center;
  }
  .eye_h1_2 {
    font-size: 20px;
  }
  .EyeFunctionalityImg {
    width: 100%;
  }
}
/* Tab-Content-CSS-Start */
.eye_h5_tabs {
  font-family: Questrial;
  font-weight: 400;
  color: white;
  height: 65px;
}
.nav-tabs {
  display: flex;
  padding: 8px 0px 8px 0px;
  border: 2px solid #ffffff12;
  border-radius: 58px;
  background: linear-gradient(
    32.95deg,
    rgba(0, 255, 255, 0.21) -84.14%,
    rgba(0, 255, 255, 0) 105.06%
  );
  justify-content: space-between;
  position: relative;
  z-index: 99;
}
.nav-tab {
  padding: 10px 32px 10px 32px;
  cursor: pointer;
  border-radius: 58px;
  margin-left: 5px;
  margin-right: 5px;
  background: transparent;
  border: 2px solid transparent;
}
.nav-tab.active {
  color: white;
  border: 2px solid #ffffff12;
  background: linear-gradient(
    32.95deg,
    rgba(0, 255, 255, 0.21) -84.14%,
    rgba(0, 255, 255, 0) 105.06%
  );
}
.eye_p_tabs {
  font-family: Roboto-Regular;
  font-weight: 300;
  font-size: 15px;
  color: white;
}
.eye_p1_tabs {
  font-family: InterLight;
  font-weight: 300;
  font-size: 12px;
  color: #a3a3a3;
}
.tab-content {
  border: 2px solid #ffffff12;
  padding: 30px 40px 30px 40px;
  border-radius: 38px;
  background: linear-gradient(
    32.95deg,
    rgba(0, 255, 255, 0.07) -84.14%,
    rgba(0, 255, 255, 0) 105.06%
  );
}
.feature_card {
  height: 220px;
}
/* Tabs Indicators Dots Style */
.carousel-indicator1 {
  background: linear-gradient(
    55.36deg,
    rgba(0, 255, 255, 0.35) -1.94%,
    rgba(0, 255, 255, 0) 115.94%
  );
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  border: none;
}
.carousel-indicator1.active {
  background-color: #00ffff !important;
  color: #00ffff;
  border: 2px solid #00ffff;
  width: 35px;
  height: 7px;
  border-radius: 20px;
}
/* Tab-Content-CSS-End */
/* Tab-ArrowBTn-CSS-Start */
.arrowbtn_secure_slider {
  width: 30px;
  height: 30px;
  border: 1px solid #00ffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    55.36deg,
    rgba(0, 255, 255, 0.35) -1.94%,
    rgba(0, 255, 255, 0) 115.94%
  );
  color: #00ffff;
}
.arroeBTNLeft {
  border: transparent;
  background-color: transparent;
  /* margin-top: -60% !important;
  margin-left: -9% !important; */
}
.arroeBTNRight {
  border: transparent;
  background-color: transparent;
  /* margin-top: -60% !important;
  margin-right: -9% !important; */
}
/* Tab-ArrowBTn-CSS-End */
@media screen and (min-width: 994px) and (max-width: 1200px) {
  .nav-tab {
    padding: 10px 10px 10px 10px;
    margin-left: 1%;
    margin-right: 1%;
  }
  .eye_h5_tabs {
    font-size: 17px;
  }
}
@media screen and (min-width: 768px) and (max-width: 994px) {
  .nav-tabs {
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 5px 0;
    border-radius: 28px;
  }
}
@media screen and (max-width: 768px) {
  .nav-tabs {
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 5px 0;
    border-radius: 28px;
  }
  .nav-tab {
    margin: 5px;
    flex: 1 0 45%;
    max-width: calc(50% - 10px);
  }
  .nav-tab {
    padding: 10px 5px 10px 5px;
    border-radius: 58px;
    margin-left: 3px;
    margin-right: 3px;
  }
  .eye_p_tabs {
    font-size: 10px !important;
    text-align: center;
  }
  .tab-content {
    padding: 20px 20px 20px 20px;
    height: auto;
  }
  .arroeBTN {
    display: none;
  }
  .mobile_indicators {
    display: none !important;
  }
  .feature_card {
    height: auto;
  }
}
@media screen and (max-width: 576px) {
  .tab-content {
    padding: 20px 10px 20px 10px;
  }
  .eye_h5_tabs {
    font-size: 14px;
    height: auto;
  }
}
/* CSS bY Umer */
@media (min-width: 768px) {
  .curiousAboutWork {
    margin-top: -22px !important;
  }
}
/* CSS bY Umer */
/* ___________________________________ */
