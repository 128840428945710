/* Careers Section */
.careere_main_div {
  background-image: url("./assets/images/BGContact.png");
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(247, 252, 255) !important;
}
.carrierBg {
  background-image: url("./assets/images/BGCareerSwati.png");
  background-position: right;
  background-size: 18% 45%;
  background-repeat: no-repeat;
  background-position: right 0px bottom 8px;
  background-color: rgb(247, 252, 255);
  height: 50%;
  width: 100%;
}
.lastUpdate.legal_h5 {
  font-weight: 700;
}
.deleteMyDataWrapper {
  height: 700px;
  width: 100%;
  position: relative;
}
.blueSection {
  background-image: url("./assets/images/Frame.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right 1px top 48px;
  height: 50%;
  width: 100%;
  background-color: #e7f2ff;
}
.PersonalDataInDeleteMyData {
  margin: 3% 0px;
}
.careerDetailLocation {
  border-bottom: 3px solid #0a76db !important;
  font-size: 12px;
}
.sliderOtherPositions {
  background-image: url("./assets/images/BGCareerDetail_2.png");
  width: 100%;
  background-size: 16% 140% !important;
  background-repeat: no-repeat;
}
.aboutTheJobCareerDetail {
  background-image: url("./assets/images/BGCareerDetail_1.png");
  background-position-x: right;
  background-position-y: 29%;
  width: 100%;
  background-size: 16% 90% !important;
  background-repeat: no-repeat;
}
.careerPageJobscard.active {
  background-color: #eef6ff;
  border-radius: 6px;
}
/* Loading Page Css Start*/

.loadingMain {
  background-image: url("../src/assets/images/loadingBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: contain;
  height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.progress-container {
  width: 190px;
  background-color: #d1d1d1;
  border-radius: 4px;
  margin-top: 1.7%;
  overflow: hidden;
}

.progress-bar {
  height: 4px;
  background-color: #244885;
  border-radius: 4px;
  transition: width 0.01s linear;
  padding: 0;
}

/* Loading Page Css End*/
.careerPageJobscard {
  background-color: transparent;
  border: none;
  width: 8%;
  font-size: 19px;
}
.careerHeroTextSection {
  margin-left: 35%;
}
.CareerPaginationRightBtn {
  background-color: #0a76db !important;
  border: none;
  color: white;
  padding: 7px;
  border-radius: 6px;
}
.CareerPaginationLeftBtn {
  background-color: #0a76db !important;
  border: none;
  color: white;
  padding: 7px;
  border-radius: 6px;
}
.heading2Sty.careerHeroHeading {
  font-size: 25px;
  font-family: "UrbanistReg";
  font-weight: 500;
}
.CareerHeroMain {
  background-color: rgb(247, 252, 255);
  width: 100%;
}
@media (max-width: 425px) {
  .pragraphSty.career {
    font-size: 10px;
    justify-content: center !important;
    margin: 0px 13px;
    text-align: center !important;
  }
  .heading2Sty.careerHeroHeading {
    font-size: 16px !important;
    margin-top: 3px;
    margin-bottom: 6px !important;
  }
}

@media (max-width: 525px) {
  .carrierBg {
    background-image: none;
  }
  .careerDetailPositions {
    font-size: 12px;
  }
  .careerDetailotherPositions {
    height: 40vh !important;
  }
  .blueSection {
    background-image: none !important;
  }
  .whiteSection {
    background-image: none !important;
  }
  .text_underlined_sm {
    text-decoration: underline;
  }
  .map_width_sm {
    width: 50vw;
  }
  .lastUpdate.legal_h5 {
    font-weight: 600 !important;
    font-size: 18.5px !important;
  }
}
@media (max-width: 526px) {
  .CareerHeroMain {
    background-color: rgb(247, 252, 255);
    margin: 0px 0px !important;
  }
  .CareerJobIconsbackground {
    background-color: #eef6ff;
    width: 42px !important;
    border-radius: 7px;
  }
}
.careerTechnTime {
  font-size: 10px;
  font-weight: 600;
  background-color: #eef6ff;
  border-radius: 7px;
  font-family: "ManropeRegular";
  padding: 6px;
  margin: 0;
}
.CareerJobIconsbackground {
  background-color: #eef6ff;
  width: 42px;
  border-radius: 7px;
}
.whiteSection {
  background-image: url("./assets/images/frame2.png");
  background-size: 30% 180%;
  background-repeat: no-repeat;
  background-position: left 0px bottom -250px;
  height: 50%;
  width: 100%;
  background-color: #f7fcff;
}
.blueSection .divider {
  height: 2px;
  width: 20px;
  background-color: #40affd;
}
.blueSection .heading h4 {
  font-size: 14px;
}
.deleteMydataForm {
  width: 100%;
  position: absolute;
  top: 20%;
  height: 500px;
}
.deleteMyDataForm textarea {
  font-size: 12px !important;
}

.deleteMyDataForm input,
.deleteMyDataForm input::placeholder {
  font-family: "ManropeRegular" !important;
  font-size: 12px !important;
}

.deleteMyDataForm {
  font-family: "ManropeRegular" !important;
  position: relative;
  background-color: #fdfdfd;
  border-radius: 10px;
  padding: 20px 20px;
  box-shadow: 0px 14.800000190734863px 48.75777816772461px 0px #0000001a;
  z-index: 12;
}
.dropdownSelectApp.show {
  border: 2px solid #0a76db !important;
  color: var(--bs-body-color);
}
.cursor-pointer {
  cursor: pointer;
}
.dropdownSelectApp {
  width: 100%;
  padding: 9px 10px;
  background-color: #f7f7f7 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #ebebeb;
  border-radius: 9px;
}
.colorSelectedApp {
  color: #757575;
  font-size: 12px !important;
}
.colorDefaultText {
  color: black;
  font-size: 12px !important;
}
.form-label.DeleteMyData {
  font-family: "UrbanistBold" !important;
  font-weight: 600 !important;
  color: #2b2b2b;
}
.listSelectApp:hover {
  background-color: #0a76db;
  border-radius: 5px;
  color: white;
}
.listSelectApp {
  font-size: 12px !important;
}
.dropdownMenuSelectApp {
  width: 100%;
  padding: 20px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dropdown-toggle::after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%238c8c8c%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 5px;
  vertical-align: middle;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
}
.formDelete input:focus {
  border: 2px solid #0a76db !important;
  outline: none;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  /* font-size: 1rem; */
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-clip: padding-box;
  border-radius: var(--bs-border-radius);
  background-color: #f7f7f7 !important;
  border-radius: 10px;
}
.formDelete .btnDelete {
  background-color: #0a76db;
  padding: 10px 15px;
  border-radius: 30px;
  border: none;
  font-size: 14px;
}

.cookiesMain {
  background-image: url("./assets/images/cookiesBanner.png");
  background-position: right 29%;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  z-index: 10;
  bottom: 0;
}
.cookiesText .cookies {
  font-family: Roboto-Bold;
  font-size: 20px;
  margin-bottom: 0;
}
.cookiesText .cookiesExplanation {
  font-family: Roboto-Regular;
  font-size: 11px;
  margin-bottom: 0;
}

.cookiesButtons .acceptCookies button {
  border: none;
  background-color: transparent;
}
.cookiesButtons .rejectCookies button {
  border: none;
  background-color: transparent;
}
.acceptCookies {
  width: 38%;
  background-color: #348cc9;
  border: 1.4px solid #40affd;
  border-radius: 7px;
  font-family: Roboto-Regular;
  color: white;
  font-size: 13px;
  text-wrap: nowrap;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rejectCookies {
  width: 38%;
  text-wrap: nowrap;
  background-color: transparent;
  height: 35px !important;
  font-family: Roboto-Regular;
  border: 1.4px solid #2b2b2b;
  border-radius: 7px;
  font-size: 13px;
  color: #2b2b2b;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cookiesCloseButton {
  padding: 5px;
  border: none;
  background: none;
}
.cookiesCloseButton .closeIcon {
  color: #2b2b2b;
}
.productMobile {
  color: white !important;
}
.servicesMobile {
  color: white !important;
}
.productMobileMenu li:hover {
  color: var(--navbar-active-color);
}
.servicesMobileMenu li:hover {
  color: var(--navbar-active-color);
}
.productMobileMenu li {
  list-style: disc;
  font-size: 10px;
  margin-top: 5px;
  font-family: UrbanistReg;
}
.servicesMobileMenu li {
  list-style: disc;
  font-size: 10px;
  margin-top: 5px;
  font-family: UrbanistReg;
}
.IsoCertificates {
  border: 2px solid #e8e8e8;
}
.DeleteDataDropdown {
  border-radius: 12px !important;
}

@media (max-width: 1024px) {
  .acceptCookies {
    font-size: 10px;
  }
  .rejectCookies {
    font-size: 10px;
  }
}
@media (min-width: 1440px) {
  .cookiesMain {
    background-image: url("./assets/images/cookiesBanner.png");
    background-position: right 29%;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@media (max-width: 768px) {
  .careerHeroTextSection {
    margin-left: 0%;
  }
  .lastUpdate.legal_h5 {
    font-weight: 600;
    font-size: 20px;
  }
  .PersonalDataInDeleteMyData {
    margin: 5% 0px !important;
  }
}

@media (max-width: 767px) {
  .cookiesMain {
    background-image: url("./assets/images/BGCookiesResponsive.png");
    background-position: right;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .acceptCookies {
    width: 50%;
  }
  .rejectCookies {
    background-color: white;
    width: 50%;
  }
  .nav_border {
    border-bottom: none !important;
  }
  .form-label.DeleteMyData {
    font-family: "UrbanistReg" !important;
    font-weight: 600 !important;
    color: #2b2b2b;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .aboutGlobeMainDiv {
    margin: auto !important;
  }
  .missionVission:hover {
    box-shadow: none !important;
  }
  .apprication img {
    width: 160px !important;
    height: auto;
  }
  .servicesBox {
    height: 235px;
    width: 170px !important;
  }
  .servicesIcons {
    width: 45px !important;
    height: 45px !important;
  }
  .servicesBox p {
    font-size: 14px !important;
  }
  .servicesBox h6 {
    font-size: 15px !important;
  }
  .headingSty.aboutMeettheMinds {
    font-size: 26px !important;
  }
  .reveniueDesign {
    width: 130px !important;
    height: 130px !important;
  }
  .reveniueDesign div p {
    font-size: 10px !important;
  }
  .home_paragrpgh_1 {
    font-size: 13px !important;
  }
  .home_btn:hover {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 30px;
    background-color: var(--navbar-link-color);
    font-family: ManropeBold;
    color: var(--home-btn-color);
    border: 1px solid transparent;
  }
  .home_btn {
    padding: 10px;
    padding-left: 70px;
    padding-right: 70px;
    border-radius: 30px;
    background-color: var(--navbar-link-color);
    font-family: ManropeBold;
    color: var(--home-btn-color);
    border: 1px solid transparent;
  }
  .home_btn:hover {
    padding: 10px;
    padding-left: 70px;
    padding-right: 70px;
    border-radius: 30px;
    background-color: var(--navbar-link-color) !important;
    font-family: ManropeBold;
    color: var(--home-btn-color) !important;
    border: 1px solid transparent;
  }
  .career_btn.careerDetails {
    padding: 8px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    border-radius: 30px;
    background-color: #0a76db;
    font-family: ManropeBold;
    color: white;
    border: 2px solid #0a76db;
    box-shadow: 0px 19px 26px -9px #0000002b;
  }
  .styleApplyNowBtn {
    color: white;
    width: 70px !important;
    background-color: rgba(10, 118, 219, 1);
    border: 1px solid rgba(10, 118, 219, 1);
    border-radius: 15.94px;
    font-size: 9px !important;
    font-weight: 500 !important;
    padding: 5px 3px;
  }
  .styleViewDetailBtn {
    color: black;
    width: 70px !important;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 15.94px;
    font-size: 9px !important;
    font-weight: 500 !important;
    padding: 5px 3px;
  }
  .careers_h3_1.careerSubHeadingFontSize {
    font-size: 19px !important;
    font-family: "ManropeSemiBold" !important;
  }
  .careers_h3.careerDetailHeading {
    color: #0a76db;
    font-family: "UrbanistBold";
    font-size: 23px !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .brand {
    width: 21% !important;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
}
