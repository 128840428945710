@font-face {
  font-family: "FixelDisplayThin";
  src: url("../../assets/fonts/FixelDisplay-Thin.ttf");
}
@font-face {
  font-family: "OutfitMedium";
  src: url("../../assets/fonts/Outfit-Medium.ttf");
}
/* Web & App Development Page */
.bgWebApp {
  background-color: #f7fcff;
}
.webPage_box4 {
  background-image: url("../../assets/images/services/Web_BG1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.webPage_p {
  color: #a4b3cb;
  font-family: ManropeRegular;
  font-weight: 400;
  font-size: 12px;
}
.webPage_p1 {
  color: #a4b3cb;
  font-family: ManropeRegular;
  font-weight: 400;
  font-size: 11px;
  text-align: end;
}
.webPage_p2 {
  color: #2b2b2b;
  font-family: ManropeRegular;
  font-weight: 600;
  font-size: 11px;
  text-align: end;
}
.webPage_btn {
  background: #254c8d;
  color: white;
  border: 1px solid white;
  border-radius: 20px;
  font-family: ManropeRegular;
  font-weight: 700;
  font-size: 13px;
}
.webPage_btn:hover {
  background: white;
  color: #254c8d;
  border: 1px solid #254c8d;
}
.webPage_btn1 {
  background: transparent;
  color: #2b2b2b;
  border: 1px solid #2b2b2b;
  border-radius: 20px;
  font-family: ManropeRegular;
  font-weight: 700;
  font-size: 13px;
}
.webPage_btn1:hover {
  background: #2b2b2b;
  color: #fff;
  border: 1px solid #2b2b2b;
  border-radius: 20px;
  font-family: ManropeRegular;
  font-weight: 400;
  font-size: 13px;
}
.webPage_box {
  top: 39%;
  left: 15%;
}
.webPage_box1 {
  top: 64%;
  right: 10%;
}
.webPage_box2 {
  margin-left: 32%;
}
.webPage_box6 {
  margin-left: 24%;
}
.webPage_box7 {
  margin-left: 39%;
}
.webPage_h2 {
  font-family: UrbanistReg;
  font-weight: 400;
}
.webPage_h6 {
  font-family: UrbanistBold;
  font-weight: 400;
}
.webPage_h6_1 {
  font-family: UrbanistBold;
  font-weight: 500;
  font-size: 15px;
}
.webPage_h5 {
  font-family: UrbanistSemiBold;
  font-weight: 400;
}
.webPage_p3 {
  font-family: ManropeRegular;
  font-weight: 400;
  font-size: 13px;
  color: #2b2b2b;
}
.webPage_p8 {
  font-family: ManropeRegular;
  font-weight: 400;
  font-size: 11px;
  color: #2b2b2b;
}
.webPage_p6 {
  font-family: ManropeBold;
  font-weight: 400;
  font-size: 12px;
  color: #a5a5a5;
}
.webPage_p4 {
  font-family: ManropeRegular;
  font-weight: 400;
  font-size: 10px;
  color: #2b2b2b;
}
.webPage_p7 {
  font-family: ManropeRegular;
  font-weight: 400;
  font-size: 11px;
  color: #2b2b2b;
}
.webPage_box3 {
  margin-left: 15%;
  margin-right: 15%;
}
.webPage_box5 {
  margin-left: 15%;
  margin-right: 15%;
}
.webPage_p5 {
  font-family: ManropeRegular;
  font-weight: 400;
  font-size: 12px;
}
.WebGlobe {
  top: 14%;
  right: 0%;
}
.WebAttendify {
  top: 20%;
  right: 0%;
}
.WebAttendifyImg {
  width: 350px;
}
.accordion {
  width: 100%;
}
.accordion-item {
  border: none !important;
}
.accordion-title {
  /* border-bottom: 1px solid #000000 !important; */
  padding: 10px;
  padding-left: 0rem !important;
  cursor: pointer;
  color: #000000;
  font-family: ManropeBold;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7fcff;
}
.accordion-content {
  padding-left: 1rem;
  display: none;
  color: #000000;
  font-family: ManropeRegular;
  font-weight: 500;
  font-size: 11px;
  background-color: #f7fcff;
}
.accordion-title.active {
  background-color: #f7fcff;
}
.accordion-content.active {
  display: block;
}
.mobile_box_97 {
  height: 150px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .webPage_p {
    font-size: 10px;
  }
  .webPage_p1 {
    font-size: 10px;
  }
  .webPage_btn {
    font-size: 10px;
  }
  .WebAttendifyImg {
    width: 250px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .webPage_box8 {
    margin-top: 20% !important;
  }
}
@media (max-width: 768px) {
  .webPage_box {
    top: 100%;
    left: 5%;
  }
  .webPage_box1 {
    display: none;
  }
  .webPage_p1 {
    font-size: 14px;
    text-align: center;
  }
  .webPage_box2 {
    margin-left: 5%;
  }
  .webPage_box3 {
    margin-left: 3%;
    margin-right: 3%;
  }
  .webPage_h2 {
    font-size: 23px;
  }
  .webPage_p3 {
    font-size: 10px;
  }
  .WebAttendifyImg {
    display: none;
  }
  .WebGlobe {
    display: none;
  }
  .webPage_box5 {
    margin-left: 5%;
    margin-right: 5%;
  }
  .mobile_box_97 {
    height: 200px;
  }
}
@media (max-width: 576px) {
  .webPage_box {
    top: 100%;
    left: 5%;
  }
  .webPage_p1 {
    font-size: 14px;
    text-align: center;
  }
  .webPage_box2 {
    margin-left: 5%;
  }
  .webPage_h2 {
    font-size: 18px;
    text-align: center;
    margin-top: 5px;
  }
  .webPage_p3 {
    text-align: center;
  }
  .webPage_box6 {
    margin-left: 5%;
  }
  .webPage_box7 {
    margin-left: 5%;
  }
  .webPage_p8 {
    text-align: center;
    font-size: 10px;
  }
  .accordion-title {
    font-size: 10px;
    padding: 8px;
  }
  .accordion-content {
    font-size: 10px;
    padding: 8px;
  }
  .WebAttendifyImg {
    display: block;
    width: 250px;
  }
  .WebAttendify {
    top: 100%;
    right: 0%;
  }
  .webPage_box8 {
    margin-top: 45% !important;
  }
  .webPage_box9 {
    margin-top: 70% !important;
  }
}
/* Slider Start */
.logo-slider-container {
  overflow: hidden;
  width: 100%;
  height: 100px;
}
.logo-slider {
  display: flex;
  animation: slide-left-right-left 10s linear infinite;
}
@keyframes slide-left-right-left {
  0% {
    transform: translateX(-100%);
  }
  25% {
    transform: translateX(50);
  }
  75% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.logo-slider img {
  flex-shrink: 0;
  width: 80px;
  height: auto;
  margin: 0 20px;
  transition: opacity 0.5s ease-in-out;
}
.logo-slider img:hover {
  opacity: 1;
}
@media (max-width: 576px) {
  .logo-slider-container {
    width: 100%;
  }
  .logo-slider img {
    margin: 0 10px;
    width: 65px;
  }
}
/* Slider End */
/* _____________________________ */
/* Web & App Development Page */
.meta_main_color {
  background-color: #0d0025;
}
.meta_main_bg {
  background-image: url("../../assets/images/services/Meta_Bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 820px;
}
.meta_h1 {
  font-family: Questrial;
  font-weight: 400;
  font-size: 43px;
}
.Constilation01 {
  top: 10%;
  right: 10%;
}
.english-word {
  font-family: FixelDisplayThin;
  font-size: 154px;
  text-align: center;
  letter-spacing: 25px;
  font-weight: 300;
}
.english-word span {
  transition: color 0.3s, filter 0.3s;
  cursor: pointer;
}
.meta_box1 {
  margin-left: 33%;
}
.meta_box2 {
  margin-left: 15%;
  margin-right: 15%;
}
.meta_box3 {
  background-image: url("../../assets/images/services/Meta_Bg_5.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* height: 85vh; */
}
.meta_box4 {
  background-image: url("../../assets/images/services/Meta_BG_Last.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 170px;
  padding-bottom: 145px;
}
.meta_box5 {
  background-image: url("../../assets/images/services/Meta_Bg_7.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.meta_p {
  font-family: RalewayRegular;
  font-weight: 600;
  background-color: #532c9e;
  display: inline-flex;
  box-shadow: 0px 4px 25px 0px #3c50e057;
}
.meta_h2 {
  font-family: Questrial;
  font-weight: 400;
}
.meta_h1_23 {
  font-family: Questrial;
  font-weight: 400;
}
.meta_p_1 {
  font-family: Roboto-Regular;
  font-weight: 400;
  color: #7c7589;
  font-size: 12px;
}
.meta_p_15 {
  font-family: Roboto-Regular;
  font-weight: 400;
  color: #7c7589;
  font-size: 12px;
}
.meta_btn {
  border-radius: 50px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-family: ManropeRegular;
  font-weight: 700;
}
.meta_btn:hover {
  background-color: white;
  color: #0d0025;
}
.meta_p_2 {
  font-family: InterLight;
  font-size: 12px;
  width: 100%;
  height: 50px;
  font-weight: 300;
  color: #a3a3a3;
}
.meta_p_3 {
  font-family: InterLight;
  font-size: 10.5px;
  font-weight: 300;
  color: #a3a3a3;
}
.meta_p_4 {
  font-family: OutfitMedium;
  font-size: 12px;
  font-weight: 500;
  color: #a09aa9;
}
.MetaIMG {
  width: 150px;
}
.MetaIMG3 {
  margin-top: -19%;
}
.MetaIMG3_Blur {
  background: #0d0025;
  width: 100%;
  height: 150px;
  filter: blur(54.6px);
  bottom: -8%;
}
.meta_small_slider_border {
  border: 2.5px solid;
  border-image-source: linear-gradient(
    138.34deg,
    rgba(224, 37, 206, 0.23) -11.36%,
    rgba(74, 237, 82, 0.23) 51.55%,
    rgba(189, 36, 230, 0.23) 113.16%
  );
  margin-left: -1% !important;
  border-image-slice: 1; /* Add this line to define the border slice */
  background: linear-gradient(
    138.84deg,
    rgba(255, 255, 255, 0) -6.39%,
    rgba(143, 143, 143, 0) 112.17%
  );
}
.meta_small_slider {
  margin-left: 15%;
  margin-right: 15%;
}

/* Arrow Btn MetaVerse */
.arrowbtn_meta_slider {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  background: linear-gradient(
    32.95deg,
    rgba(0, 255, 255, 0.21) -84.14%,
    rgba(0, 255, 255, 0) 105.06%
  );
  border: 1px solid #ffffff12;
  color: white;
  justify-content: center;
  text-align: center;
}
.arrowbtn_meta_slider.disabled {
  background: #0d0529;
  border: 1px solid #ffffff12;
  color: #27478C;
  pointer-events: none;
}
.arrowbtn_meta_slider.active {
  background: linear-gradient(
    32.95deg,
    rgba(0, 255, 255, 0.21) -84.14%,
    rgba(0, 255, 255, 0) 105.06%
  );
  color: white;
}
.activeBtnMeta {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: transparent;
  background: linear-gradient(
    55.36deg,
    #ec06c9 -1.94%,
    rgba(0, 255, 255, 0) 115.94%
  );
  color: white;
}
.meta_slider_mobileBTN {
  border: 2px solid #ffffff12;
  margin-top: 10px;
  background: linear-gradient(
    32.95deg,
    rgba(0, 255, 255, 0.21) -84.14%,
    rgba(0, 255, 255, 0) 105.06%
  );
}
.inactiveBtnMeta {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: transparent;
  color: #2b2b2b;
  background: linear-gradient(
    55.36deg,
    #6d3e66 -1.94%,
    rgba(0, 255, 255, 0) 115.94%
  );
  color: white;
}
/* ______________________________ */
/* Meta Carousel Slider Start */
.meta_Slider_box {
  border: 2px solid #ffffff12;
  /* height: 422px; */
  background: linear-gradient(
    32.95deg,
    rgba(0, 255, 255, 0.21) -84.14%,
    rgba(0, 255, 255, 0) 105.06%
  );
  border-radius: 36px;
}
.meta_back {
  top: -10% !important;
  z-index: 999 !important;
  position: absolute !important;
}
.meta_list {
  font-family: ManropeRegular;
  font-weight: 300;
  font-size: 12px;
}
.meta_slider_p {
  font-family: InterLight;
  font-weight: 300;
  font-size: 11px;
  color: #a3a3a3;
}
.meta_slider_h2 {
  font-family: Questrial;
  font-weight: 400;
}
.arrow_meta_slider {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    32.95deg,
    rgba(0, 255, 255, 0.21) -84.14%,
    rgba(0, 255, 255, 0) 105.06%
  );
  border: 2px solid #ffffff12;
}
/* Meta Carousel Slider End */

@media (min-width: 768px) and (max-width: 1024px) {
  .english-word {
    font-size: 80px;
    letter-spacing: 10px;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .english-word {
    font-size: 55px;
    letter-spacing: 0px;
  }
}
@media (max-width: 768px) {
  .meta_h2 {
    font-size: 20px;
  }
  .meta_box1 {
    margin-left: 10%;
  }
  .meta_box2 {
    margin-left: 2%;
    margin-right: 2%;
  }
  .meta_p_3 {
    font-size: 8px;
  }
}
@media (max-width: 568px) {
  .meta_h2 {
    font-size: 20px;
  }
  .MetaIMG3 {
    margin-top: 0%;
  }
  .MetaIMG {
    width: 100px !important;
  }
  .meta_box3 {
    background-image: url("../../assets/images/services/Meta_Bg_4.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .meta_Slider_box {
    border-radius: 20px;
  }
  .meta_h1 {
    font-size: 22px;
  }
  .MetaIMG3_Blur {
    display: none;
  }
  .meta_p_3 {
    font-size: 11px;
  }
  .meta_small_slider {
    margin-left: 5%;
    margin-right: 5%;
  }
  .meta_main_bg {
    height: 500px;
  }
  .meta_box4 {
    padding-top: 70px;
    padding-bottom: 45px;
  }
}
