@font-face {
  font-family: "UrbanistReg";
  src: url("../src/assets/fonts/Urbanist-Regular.ttf");
}
@font-face {
  font-family: "UrbanistBold";
  src: url("../src/assets/fonts/Urbanist-Bold.ttf");
}
@font-face {
  font-family: "UrbanistSemiBold";
  src: url("../src/assets/fonts/Urbanist-SemiBold.ttf");
}
@font-face {
  font-family: "ManropeBold";
  src: url("./assets/fonts/Manrope-Bold.ttf");
}
@font-face {
  font-family: "ManropeExtraBold";
  src: url("./assets/fonts/Manrope-ExtraBold.ttf");
}
@font-face {
  font-family: "ManropeRegular";
  src: url("./assets/fonts/Manrope-Regular.ttf");
}
@font-face {
  font-family: "ManropeSemiBold";
  src: url("./assets/fonts/Manrope-SemiBold.ttf");
}
@font-face {
  font-family: "ManropeExtraLight";
  src: url("./assets/fonts/Manrope-ExtraLight.ttf");
}
@font-face {
  font-family: "ManropeLight";
  src: url("./assets/fonts/Manrope-Light.ttf");
}
@font-face {
  font-family: "ManropeMedium";
  src: url("./assets/fonts/Manrope-Medium.ttf");
}
@font-face {
  font-family: "MerriweatherBold";
  src: url("./assets/fonts/Merriweather-Bold.ttf");
}
@font-face {
  font-family: "MerriweatherLight";
  src: url("./assets/fonts/Merriweather-Light.ttf");
}
@font-face {
  font-family: "MerriweatherRegular";
  src: url("./assets/fonts/Merriweather-Regular.ttf");
}
/* Font Roboto Added for cookies section */
@font-face {
  font-family: "Roboto-Black";
  src: url("./assets/fonts/Roboto-Black.ttf");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("./assets/fonts/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Roboto-Light";
  src: url("./assets/fonts/Roboto-Light.ttf");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("./assets/fonts/Roboto-Medium.ttf");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("./assets/fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Roboto-Thin";
  src: url("./assets/fonts/Roboto-Thin.ttf");
}

:root {
  --navbar-link-color: white;
  --navbar-active-color: #0a76db;
  --text-light-gray: #8c8c8c;
  --text-light-black: #2b2b2b;
  --home-text-color: #d9d9d9;
  --home-btn-color: #303030;
  --footer-background-color: #000e26;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "UrbanistReg";
}
p {
  font-family: "ManropeRegular";
}
body {
  /* background-color: red; */
  background-color: #f7fcff;
  overflow-x: hidden;
}
.nav-link {
  cursor: pointer;
}
.gapSty-8 {
  gap: 8%;
}
.mb-1-5 {
  margin-bottom: 0.15rem !important;
}
.navbar-collapse {
  flex-grow: unset;
}
.ml-5 {
  margin-left: 35%;
}
.mr-1 {
  margin-right: 10px;
}
.ml-1 {
  margin-left: 10px;
}
.mt-10 {
  margin-top: 50px;
}
.pl-2 {
  padding-left: 20px;
}
.grayTxt {
  color: rgba(150, 150, 150, 1);
}
.pl-5 {
  padding-left: 50px;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}
.pl-0 {
  padding-left: 0px !important;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.serviesTech {
  padding: 70px 0;
}
.pragraphSty {
  font-size: 15px;
  font-weight: 500;
  font-family: "ManropeRegular";
}
.pragraphStyMission {
  font-size: 12px;
  font-weight: 500;
  height: 55px;

  font-family: "ManropeRegular";
}
.pragraphSty.contactFontSize {
  font-size: 12px;
  font-weight: 700;
  font-family: "ManropeSemiBold";
}
.headingSty {
  font-size: 27px !important;
  font-weight: 300;
  font-family: "UrbanistReg";
}
.headingSty4 {
  font-size: 27px;
  font-weight: 300;
  font-family: "UrbanistReg";
}
.mapImages {
  width: 100%;
  height: 100%;
}
.heading2Sty {
  font-size: 25px;
  font-family: "UrbanistReg";
  font-weight: 500;
}
.heading2Sty.homePage {
  font-size: 20px !important;
  font-family: "UrbanistReg";
  font-weight: 500;
}
.pragraph2Sty {
  font-size: 18px;
  /* line-height: 31px; */
  font-weight: 400;
}
.borderline {
  border-left: 2px solid;
  border-color: rgba(31, 49, 65, 0.09);
}
.hrColor {
  border-top: 2px solid;
  border-color: rgba(31, 49, 65, 0.09);
}
.harnessing {
  padding: 20px;
  background: rgba(31, 49, 65, 0.06);
  border-radius: 30px;
}

.mainPage {
  background-color: #f7fcff;
  padding-left: 0px;
  padding-right: 0px;
}
.harnessing,
.slider_background {
  margin: 0 13px;
}

.swatiIconbg {
  background-image: url("./assets/images/SwatitechlogoBG.png");
  background-size: contain;
  /* padding: 20px 0px; */
  background-repeat: no-repeat;
  background-position: center;
}
.shouldCase {
  background-image: url("./assets/images/BGService.png");
  background-size: cover;
  padding: 30px;
  margin: 80px 0;
}
.touchusBtn {
  padding: 16px, 30px, 16px, 30px;
  width: 250px;
  height: 50px;
  background-color: #fff;
  border-radius: 30px;
  font-weight: bolder;
}
.servicesBox {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  box-shadow: 0px 1.852px 3.148px 0px rgba(0, 0, 0, 0.01),
    0px 8.148px 6.519px 0px rgba(0, 0, 0, 0.01),
    0px 20px 13px 0px rgba(0, 0, 0, 0.01),
    0px 38.519px 25.481px 0px rgba(0, 0, 0, 0.02),
    0px 64.815px 46.852px 0px rgba(0, 0, 0, 0.02),
    0px 100px 80px 0px rgba(0, 0, 0, 0.03);
}
.servicesIcons {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: rgba(64, 175, 253, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  box-shadow: 0px 1.852px 3.148px 0px rgba(0, 0, 0, 0.01),
    0px 8.148px 6.519px 0px rgba(0, 0, 0, 0.01),
    0px 20px 13px 0px rgba(0, 0, 0, 0.01),
    0px 38.519px 25.481px 0px rgba(0, 0, 0, 0.02),
    0px 64.815px 46.852px 0px rgba(0, 0, 0, 0.02),
    0px 100px 80px 0px rgba(0, 0, 0, 0.03);
}
.servicesBox h6 {
  font-size: 16px;
  font-weight: 600;
  font-family: UrbanistReg;
}
.servicesBox p {
  font-size: 14px;
  font-family: "ManropeRegular";
  font-weight: 400;
  padding: 10px 0px;
}
.ourClientsDesign {
  background-color: #f7fcff;
  padding: 50px 0px;
}
.md-position-relative {
  position: relative;
}
.md-position-absolute {
  position: absolute;
}
.clientName {
  /* margin-top: 10px; */
  padding: 50px 0;
}

.brand {
  width: 18%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.circle_boxShadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  background-color: #fff;
}
.circle_boxLine {
  border: 0.94px solid #e3e8eb;
}
.circle_boxLine2 {
  border: 0.94px solid #2a4c606e;
}
.bluePls {
  color: rgba(64, 175, 253, 1);
  margin-left: -7px;
}
.reveniueDesign div p {
  padding: 0px 12px;
  margin: 0px !important;
  font-size: 12px;
}
.reveniueDesign {
  border-radius: 100%;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.intenationlClient {
  width: 100%;
  height: auto;
}
.awssld__content {
  background-color: transparent;
}
.globalizedSty {
  font-size: 12px;
  font-weight: 400;
  font-family: "ManropeRegular";
  border-left: 2.5px solid #0a76db;
  padding-left: 8px;
}
.globalImg {
  width: 100%;
}
.globalImg img {
  width: 100%;
  height: 100%;
}
.divderLine {
  border-bottom: 5px solid;
  border-color: rgba(31, 49, 65, 0.09);
}
.borderline5px {
  border-left: 5px solid;
  border-color: rgba(31, 49, 65, 0.09);
}
.Certificate {
  border: 2px solid #e8e8e8;
  border-radius: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px 0px;
  background-color: rgba(247, 252, 255, 1);
}
/* .CertificateBox {
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
} */
.CertificateBox img {
  width: 65%;
}
.QRS {
  font-size: 26px;
  font-weight: 700;
  font-family: ManropeBold;
  padding: 5px;
  text-align: center;
}
.certificatpara {
  background-color: rgba(0, 73, 135, 1);
  font-size: 11px;
  color: #fff;
  text-align: center;
}
.apprication {
  width: 80%;
}
.apprication img {
  width: 100%;
  height: auto;
}
.teamProfile {
  margin-bottom: 20px;
}

.OurTeam {
  display: flex;
  justify-content: center;
  height: 220px;
  width: 100%;
  position: relative;
  /* padding: 5px 0px; */
  border-radius: 13px;
  border: 6px solid rgba(238, 238, 238, 1);
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.OurTeam img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.teamDetails {
  position: absolute;
  background-color: #fff;
  width: 90%;
  border-radius: 10px;
  bottom: 20px;
  left: 5%;
  padding: 5px;
}
.teamDetails h6 {
  text-align: center;
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 14px;
}
.teamDetails p {
  text-align: center;
  font-size: 10px;
  margin-bottom: 0px;
  font-weight: 600;
  color: rgba(6, 85, 140, 1);
}
.modal-body .container-fluid {
  margin-top: 10px;
  border-radius: 13px;
  border: 1px solid #343434 !important;
  background: rgba(0, 3, 9, 0.52);
  backdrop-filter: blur(15px);
  padding: 15px 8px 0px 8px ;
}
.px-2o5{
  padding: 0px 7px;
}
.mb-2o5{
  margin-bottom: 15px;
}
.modal_navbar_box:hover {
  outline: 1px solid #0a76db;
  background-color: #111721 !important;
}

/* //usman */

.modal_navbar_box {
  padding: 15px !important;
  border: 1px solid;
  border-color: rgba(13, 81, 146, 0.35);
  cursor: pointer;
}
/* .featureTag {
  background-color: rgba(10, 118, 219, 1);
  color: #fff;
  text-align: center;
  border: 2px solid rgba(10, 118, 219, 1);
  border-radius: 4px;
}
.featureTag:hover {
  background-color: rgba(10, 118, 219, 1);
  color: #fff;
} */
.careersHero {
  border-radius: 10px;
}
.jobsCard {
  margin-bottom: 22px !important;
  /* margin-left: 10px;
  margin-right: 10px; */
  width: 100%;
  border-radius: 9px !important;
}
/* .jobsCard:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
  border: none;
} */
.jobsCard h4 {
  font-weight: 400;
  font-size: 15px;
  color: rgba(43, 43, 43, 1);
}
.styleApplyNowBtn {
  color: white;
  width: 80px;
  background-color: rgba(10, 118, 219, 1);
  border: 1px solid rgba(10, 118, 219, 1);
  border-radius: 15.94px;
  font-size: 10px;
  font-family: "ManropeSemiBold";
  font-weight: 500 !important;
  padding: 5px 5px;
}
.careerJobTitle {
  font-weight: 600;
  font-size: 14px;
  text-wrap: nowrap;
  font-family: "ManropeSemiBold";
}
.styleViewDetailBtn {
  color: black;
  width: 80px;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 15.94px;
  font-size: 10px;
  font-family: "ManropeSemiBold";
  font-weight: 500 !important;
  padding: 5px 5px;
}
.googleMapLocation {
  width: 100%;
  height: 550px;
  position: relative;
}
.googleMapLocation .mainMap {
  width: 100%;
  height: 100%;
}
.pinImageUSA {
  position: absolute;
  top: 36%;
  left: 25%;
  z-index: 1;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.pinImageUAE {
  position: absolute;
  top: 49%;
  left: 61%;
  z-index: 1;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.pinImageKHI {
  position: absolute;
  top: 47%;
  left: 65%;
  z-index: 1;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.pinImageLHE {
  position: absolute;
  top: 40%;
  left: 65%;
  z-index: 1;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.xproHotspotTooltipText {
  position: absolute;
  background-color: rgba(247, 252, 255, 1);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #000;
  padding: 10px;
  border-radius: 15.94px;
  display: none;
  z-index: 2;
}
.pinImageUSA:hover + .xproHotspotTooltipText {
  display: block;
  top: 8%;
  left: 15.5%;
}
.pinImageUAE:hover + .xproHotspotTooltipText {
  display: block;
  top: 21%;
  left: 53%;
}
.pinImageLHE:hover + .xproHotspotTooltipText {
  display: block;
  top: 12%;
  left: 58%;
}
.pinImageKHI:hover + .xproHotspotTooltipText {
  display: block;
  top: 19%;
  left: 56.5%;
}
.xproHotspotTooltipText p {
  text-align: start;
  margin-bottom: 5px;
}

.tooltip-bottom::before {
  content: "";
  position: absolute;
  display: block;
  width: 0px;
  left: 50%;
  bottom: 5px;
  border: 15px solid transparent;
  border-bottom: 0;
  border-top: 15px solid rgba(247, 252, 255, 1);
  transform: translate(-50%, calc(100% + 5px));
}

.carrierDetails {
  background-image: url("./assets/images/BG_CareerDetail.png");
  background-position: contain;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.smDisplayNone {
  display: none;
}
.CopyRightSm {
  display: none;
  padding: 10px 0px;
}
.CopyRightLg {
  display: block;
}
.CopyRightSm p {
  font-size: 10px;
}
.smDisplay {
  display: none;
}
.attendifyPage li a,
.attendifyPage li .nav-link,
.attendifyPage li .nav-link .lucide {
  color: #000 !important;
}
.missionVission {
  border-radius: 19.383px;
  border: 4px solid rgba(232, 232, 232, 0.6);
  background-color: #f7fcff;
  padding: 20px;
}
.missionVission:hover {
  box-shadow: 0px 18px 59.3px 0px rgba(0, 0, 0, 0.1);
}
.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}
/* .smMarginVivew {
  display: flex !important;
} */
/* Extra small devices (phones, less than 576px) */
@media only Screen and (min-width: 992px) and (max-width: 1024px) {
  .careerJobTitle {
    font-weight: 600;
    font-size: 13px;
    text-wrap: nowrap;
    font-family: "ManropeSemiBold";
  }
}
@media (max-width: 575.98px) {
  .careerJobTitle {
    text-wrap: wrap;
    margin-top: 5px !important;
  }
  .md-position-relative {
    position: unset;
  }
  .workseeLogo {
    display: none;
    margin: 50px;
  }
  .missionVission {
    border: none !important;
  }
  .missionVission:hover {
    box-shadow: unset;
  }
  .jobsCard {
    width: 96%;
  }
  .missionVission.ourMission {
    border-radius: 19.383px;
    /* border-bottom: 3px solid rgba(232, 232, 232, 0.6) !important; */
  }
  .careersHero {
    border-radius: 0px !important;
  }
}
@media (max-width: 767px) {
  .careerJobTitle.careerDetail {
    text-align: center !important;
  }
}
@media (max-width: 992px) {
  .careerJobTitle.careerDetail {
    text-wrap: wrap;
  }
}
@media (max-width: 375px) {
  .reveniueDesign div p {
    font-size: 10px !important;
  }
  .reveniueDesign {
    width: 120px;
    height: 120px;
  }
  .headingSty4.HomeHeading {
    font-size: 15px !important;
  }
  .headingSty4.ourProduct {
    font-size: 18px !important;
  }
}
@media (min-width: 300px) and (max-width: 767.98px) {
  .reveniueDesign div p {
    font-size: 10px !important;
  }
  .md-mt-4 {
    margin-top: 40px;
  }
  .borderline5px {
    border: none;
  }
  .Certificate {
    margin-bottom: 20px;
  }
  .globalImg {
    margin-top: 30px;
  }

  .mt-10 {
    margin-top: 0px;
  }
  .ourClientsDesign {
    padding: 20px;
  }
  .gapSty-8 {
    display: flex;
    justify-content: space-between !important;
  }
  .smScreen {
    padding: 0px !important;
    margin: 0px !important;
  }
  .smScrenRes {
    margin: 20px 0px !important;
    padding: 0px !important;
  }
  .headingSty,
  .headingSty4 {
    font-size: 24px;
    text-align: center;
  }
  .headingSty4.ourProduct {
    font-size: 22px;
    text-align: center;
    margin: 0px 1px;
  }
  .certi_main_div {
    background-image: none;
    background-color: #f7fcff;
  }
  .QRS {
    font-size: 20px;
  }
  .certificatpara {
    margin-bottom: 0.5rem;
  }
  .smTxtCenter {
    text-align: center;
  }
  .smAlignmentCenter {
    display: flex;
    justify-content: center;
  }
  .smAlignmentStart {
    display: flex;
    justify-content: flex-start !important;
  }
  .apprication {
    width: 100%;
  }
  .apprication p svg {
    width: 100%;
  }
  .pragraphSty {
    font-size: 11px;
    justify-content: center !important;
    margin: 13px 0px;
  }
  .pragraphCareer.careerHeroSectionTextMobile {
    font-size: 11px;
    justify-content: center !important;
    margin: 0px !important;
  }
  .pragraphStyMission {
    font-size: 11px;
    height: unset !important;
    /* justify-content: center !important; */
  }
  .shouldCase {
    padding: 0px !important;
    margin: 0px !important;
  }
  .OurTeam {
    height: 170px;
  }
  .teamDetails {
    bottom: 5px;
  }
  .teamDetails h6 {
    font-size: 12px;
  }
  .teamDetails p {
    font-size: 8px;
  }
  .smDisplayNone {
    display: block;
  }
  .CopyRightLg {
    display: none;
  }
  .CopyRightSm {
    display: block;
  }
  /* .row {
    --bs-gutter-x: 1rem !important;
  } */
  .serviesDetails {
    text-align: center;
  }
  .smDisplay {
    display: block;
  }
  .mapImages {
    width: 90%;
    display: flex;
    /* padding-top: 30px; */
    padding-bottom: 15px;
  }
  .serviesTech {
    padding: 20px 10px;
  }
  .borderline {
    border: none;
    margin-top: 15px;
  }
  .harnessing {
    padding: 5px !important;
    margin: 15px 0px;
  }
  /* .heading2Sty {
    font-size: 16px;
  } */
  .careersHero {
    padding: 10px 0px !important;
  }
  .heading2Sty.careerHeroHeading {
    font-size: 16px !important;
    margin-top: 3px;
    margin-bottom: 10px;
  }
  .hrColor {
    margin: 20px 0px !important;
  }
  .smShouldView {
    margin: 20px 0px !important;
  }
  .servicesBox {
    margin-top: 20px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .md-position-absolute {
    position: unset;
    padding-bottom: 20px;
  }
  .smShouldView p {
    text-align: center;
  }
  .smMarginVivew.HomeSection {
    display: flex;
    flex-direction: column-reverse !important;
    margin: 0px !important;
    padding: 0px !important;
    text-align: center;
  }
  .smMarginVivew {
    display: flex;
    margin: 0px !important;
    /* padding: 0px !important; */
    text-align: center;
  }
  .smTextView .headingSty {
    text-align: left;
  }
  .sm-py-20 {
    padding: 20px 0px !important;
  }
  .smTextStart {
    text-align: start;
  }
  .clientName {
    padding: 0px;
  }
  .modal_navbar_box {
    width: 100%;
  }
  .text_align_sm {
    text-align: center !important;
  }
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .md-position-relative {
    position: unset;
  }

  .mt-10 {
    margin-top: 0px;
  }
  .OurTeam {
    width: 75%;
  }
  .serviesTech {
    padding: 30px 10px !important;
  }
}
/* @media (min-width: 1080px) and (max-width: 1200px) {
.pragraphStyMission{
  font-size: 10px;
}
} */
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .brandlog {
    gap: 5px;
  }
}
/* @media (min-width: 1400px) {
  .navbar-expand-md .navbar-nav{
    gap: 5% !important;
  }
} */
@media (min-width: 1650px) {
  .navbar-nav.first .nav-link {
    font-size: 16px;
  }
  .home_h3 {
    font-size: 25px;
  }
  .home_paragrpgh_1 {
    font-size: 16px;
  }
  .headingSty4 {
    font-size: 45px;
  }
  .pragraphSty {
    font-size: 16px;
  }
  .pragraphStyMission {
    font-size: 16px;
    height: 55px;
  }
  .servicesBox p {
    font-size: 16px;
  }
  .servicesBox h6 {
    font-size: 20px;
  }
  .footer_para {
    font-size: 14px;
  }
  .footer_para_1 {
    font-size: 16px;
    margin: 20px 0px;
  }
  .footer_h5 {
    font-size: 25px;
  }
  .modal_navbar_p1 {
    font-size: 18px;
  }
  .legal1_main_div {
    background-size: cover;
  }
  /* .carrierDetails {
    background-size: cover;
  } */
}
